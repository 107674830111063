import "./Impressum.css";
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";
import BackButton from "../sharedcomponents/backButton/BackButton";

function Impressum() {
    return (
        <>
            <div className="impressum-container">
                <Link to={"/settings/home/"}><BackButton text="Zurück" /></Link>
                <h2>Impressum</h2>
                <h1> Impressum Angaben gemäß § 5 TMG</h1>
                <p>TicTrack UG (haftungsbeschränkt)<br/>Heinrich-Imbusch-Str. 30 <br/>45665 Recklinghausen</p>
                <p>Handelsregister: HRB 9461<br/>Registergericht: Amtsgericht Recklinghausen</p>
                <p><span className="strong-text">Vertreten durch:</span><br/> Christian Maleszka (Geschäftsführer)</p>
                <h1>Kontakt</h1>
                <p>Telefon: +49 (0) 2361 8903170<br/>E-Mail: info@tictrack.de</p>
                <h1>Umsatzsteuer-ID</h1>
                <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>DE362308189</p>
                <h1>Redaktionell verantwortlich</h1>
                <p>Christian Maleszka<br/>Heinrich-Imbusch-Str. 30<br/>45665 Recklinghausen</p>
                <h1>EU-Streitschlichtung</h1>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.<br/>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                <h1>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h1>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </div> 
            <Footer />
            <CookieBanner />
        </>
    )
}

export default Impressum;