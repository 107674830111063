import "./ResetMail.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useState} from "react";
import Footer from "../../footer/Footer";
import CookieBanner from "../../sharedcomponents/CookieBanner";
import PrimaryButton from "../../sharedcomponents/primaryButton/PrimaryButton";

function ResetMail() {

    const [sendToNewMailSuccessful, setSendToNewMailSuccessful] = useState(false);
    const [sendToNewMailError, setSendToNewMailError] = useState(false);

    return (
        <>
            <div className="e-mail-reset-container">
            <h3>TicTrack</h3>
            <h5>Bitte geben Sie nun eine neue gültige E-Mail-Adresse ein.</h5>
            <Form.Group className="mb-3">
                <Form.Label>Neue E-Mail Adresse</Form.Label>
                <div className="admin-form-edit-container">
                    <Form.Control id="NewEmail1" type="email" />
                </div>
                <Form.Label>E-Mail Adresse bestätigen</Form.Label>
                <div className="admin-form-edit-container">
                    <Form.Control id="NewEmail2" type="email" />
                </div>
                <PrimaryButton text="E-Mail Adresse ändern" onClick={sendRequestToChangeMail}/>
            </Form.Group>
            {sendToNewMailSuccessful && <div className="reset-mail-change-mail-successful">
                <p>Eine Bestätigung wurde an Ihre neue E-Mail Adresse gesendet.</p>
            </div>}
            {sendToNewMailError &&<div className="reset-mail-change-mail-error">
                <p>Ein Fehler ist aufgetreten. Prüfen Sie Ihre Eingaben und versuchen Sie es erneut.</p>
            </div>}
            </div>
            <Footer />
            <CookieBanner/>
        </>
    )

    function sendRequestToChangeMail() {
        let mailInput1 = document.getElementById("NewEmail1").value;
        let mailInput2 = document.getElementById("NewEmail1").value;

        if (mailInput1 === mailInput2) {
            axios.put('account/changeEmail/setNewMail', {
                "reset_email_token": new URLSearchParams(window.location.search).get("token").toString(),
                "new_mail": mailInput1
            }).then(() => {
                setSendToNewMailSuccessful(true);
            }).catch(() => {
                setSendToNewMailError(true);
            })
        }


    }
}

export default ResetMail;