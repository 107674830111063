import "./AdminList.css";
import AdminItem from "../item/AdminItem";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import axios from "axios";
import Logout from '../../../sharedcomponents/Logout'

function AdminList() {
    const [adminList, setAdminList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [limitAdmins, setLimitAdmins] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        axios.get('administrator/getAll').then(results => {
            setAdminList(results.data);
            setIsLoading(false);
        }).catch(() => { Logout()})

        axios.get('account/getInfo').then(results => {
            setLimitAdmins(results.data.admin_account_limit)
        })
    }, [])
    
    function createAdminItems(){
        let admins = [];

        adminList.map((item, index) => {
            admins.push(
                <a key={index} href={"adminaccounts/adminView?username=" + item.username}>
                    <AdminItem name={item.name} username={item.username} />
                </a>
            )
        })
        return admins;
    }

    return isLoading ? 
        <LoadingSpinner/>
        :
        <div className="admin-list-container">
            <h4>Anzahl Administratoren: {adminList.length}/{limitAdmins} </h4>
            {createAdminItems()}
        </div>
    
}

export default AdminList;