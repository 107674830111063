import "./TimeRecordingConfiguration.css";
import {useEffect} from "react";
import axios from "axios";
import TrackingConfiguration from "./trackingConfiguration/TrackingConfiguration";
import TabletConfiguration from "./tabletConfiguration/TabletConfiguration";
import { Button } from "@mui/material";
import AskForAccess from "../../sharedcomponents/AskForAccess";
import PrimaryButton from "../../sharedcomponents/primaryButton/PrimaryButton";

function TimeRecordingConfiguration() {

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        AskForAccess();
    })

    const updateSettings = () => {
        axios.put('settings/edit', {
            "new_max_shift_duration": JSON.parse(sessionStorage.getItem("Changing Settings")).trackingLimit,
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            
        })
    }

    return (
        <div className="qr-settings-page-container">
            <div className="qr-config-header">
                <h3>QR-Tablets konfigurieren</h3>
                <PrimaryButton text="Alle Änderungen speichern" onClick={updateSettings} />
            </div>
            <hr className="divider-solid"/>
            <TabletConfiguration />
            <div className="qr-config-header">
                <h3>Zeiterfassung konfigurieren</h3>
            </div>
            <hr className="divider-solid"/>
            <TrackingConfiguration />
        </div>
    )
}

export default TimeRecordingConfiguration;