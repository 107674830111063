import "./LoginPage.css";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";
import LoginForm from "./login/form/LoginForm";
import { Link } from "react-router-dom";


function LoginPage() {
    return (
        <>
            <div className="login-container"
                style={{ backgroundImage: "url(/Webseiten-BG.png)", backgroundSize: "100% 100%"}}
            >
                <div className="login-field">
                    <h3>TicTrack</h3>
                    <h2 style={{fontWeight : "bold"}}>Zugang für</h2>
                    <h2 style={{fontWeight : "bold"}}>Unternehmensinhaber</h2>
                    <h6 style={{color : "gray", textAlign: "center"}}>Willkommen zurück. Bitte melden Sie sich an.</h6>
                    <LoginForm/>
                    <div className="want-make-an-Account-question">
                        <span style={{textAlign: "center"}}>Sie haben noch keinen Account? </span>
                        <Link to="/register">Account erstellen</Link>
                    </div>

                </div>
            </div>
            <Footer />
            <CookieBanner/>
        </>
    )
}

export default LoginPage;