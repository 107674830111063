import "./Agb.css";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";
import { Link } from "react-router-dom";
import BackButton from "../sharedcomponents/backButton/BackButton";

function Agb() {
    return (
        <>
            <div className="agb-container">
                <Link to={"/settings/home/"}><BackButton text="Zurück" /></Link>
                <h2>Allgemeine Geschäftsbedingungen - Stand 25.09.2023</h2>
                <p>Diese Allgemeinen Geschäftsbedingungen (AGB) der TicTrack UG (haftungsbeschränkt) Heinrich-Imbusch-Str. 30, 45665, Recklinghausen, vertreten durch den vertretungsberechtigten Geschäftsführer Christian Maleszka, gelten gegenüber allen Kunden. Der Vertrag richtet sich ausschließlich an Unternehmen im Sinne des § 14 BGB.</p>
                <h3>§ 1 Vertragsgegenstand</h3>
                <p>Gegenstand der vorliegenden AGB ist die zeitlich auf die Dauer des Vertrags begrenzte Gewährung der Nutzung der „TicTrack“ - Plattform (nachfolgend „Plattform“) über das Internet. Die Plattform dient der digitalen Zeiterfassung.</p>
                <h3>§ 2 Leistungen des Anbieters; Plattform und Speicherplatz</h3>
                <p>(1) Der Anbieter gewährt dem Kunden die Nutzung der jeweils aktuellsten Version der Plattform für die vereinbarte Anzahl an berechtigen Nutzern über das Internet mittels Zugriff durch einen Browser.</p>
                <p>(2) Der Anbieter gewährleistet die Funktionsfähigkeit und Verfügbarkeit der Plattform während der Dauer des Vertragsverhältnisses und wird diese in einem zum vertragsgemäßen Gebrauch geeigneten Zustand erhalten. Es können unterschiedliche Pakete gebucht werden, sodass sich der Funktionsumfang der Plattform aus der jeweiligen Leistungsbeschreibung ergibt.</p>
                <p>(3) Weiterhin ermöglicht der Anbieter dem Kunden nach Vertragsschluss in elektronischer Form den Zugriff auf eine Benutzerdokumentation. Die Benutzerdokumentation ist jederzeit während Nutzung der Plattform einsehbar und kann in einem gängigen Format heruntergeladen werden.</p>
                <p>(4) Der Anbieter kann die Plattform jederzeit aktualisieren sowie weiterentwickeln und insbesondere aufgrund einer geänderten Rechtslage, technischer Entwicklungen oder zur Verbesserung der IT-Sicherheit anpassen. Der Anbieter wird dabei die berechtigten Interessen des Kunden angemessen berücksichtigen und den Kunden rechtzeitig über notwendige Updates informieren. Im Falle einer wesentlichen Beeinträchtigung der berechtigten Interessen des Kunden, steht diesem ein Sonderkündigungsrecht zu, das gilt ebenfalls bei Preiserhöhungen.</p>
                <p>(5) Eine Anpassung auf die individuellen Bedürfnisse oder die IT-Umgebung des Kunden schuldet der Anbieter nicht, es sei denn die Parteien haben abweichendes vereinbart.</p>
                <p>(6) Der Anbieter wird regelmäßig Wartungen an der Plattform vornehmen und den Kunden hierüber rechtzeitig informieren. Die Wartung wird regelmäßig außerhalb der üblichen Geschäftszeiten des Kunden durchgeführt, es sei denn aufgrund zwingender Gründe muss eine Wartung zu einer anderen Zeit vorgenommen werden.</p>
                <p>(7) Der Anbieter wird dem Stand der Technik entsprechende Maßnahmen zum Schutz der Daten vornehmen.</p>
                <p>(8) Ein Anspruch auf Abschluss eines Vertrages besteht nicht. Der Anbieter kann eine Registrierung ohne Angabe von Gründen ablehnen. In diesem Fall werden die übermittelten Daten unverzüglich gelöscht.</p>
                <h3>§ 3 Nutzungsumfang und -rechte, Registrierung</h3>
                <p>(1) Eine physische Überlassung der Plattform an den Kunden erfolgt nicht.</p>
                <p>(2) Der Kunde erhält an der jeweils aktuellsten Version der Plattform für die vertraglich festgelegte Anzahl an Nutzern einfache, d. h. nicht unterlizenzierbare und nicht übertragbare, zeitlich auf die Dauer des Vertrags beschränkte Rechte die Plattform mittels Zugriff über einen Browser nach Maßgabe der vertraglichen Regelungen zu nutzen.</p>
                <p>(3) Die Benutzerkonten werden personengebunden zur Verfügung gestellt. Dem Kunden ist eine weitergehende Nutzung der Plattform nicht gestattet.</p>
                <p>(4) Der Kunde erstellt einen Unternehmens-Account auf company.tictrack.de und bestätigt seine E-Mail. Danach bekommt dieser die Möglichkeit, ein Abo-Modell zu buchen. Auf app.tictrack.de erfolgt die Erbringung der vertraglichen Hauptleistungspflicht. Die TicTrack UG (haftungsbeschränkt) nutzt zudem noch client.tictrack.de und tictrack.de. Die genauen Leistungsbeschreibungen und Preise ergeben sich aus dem jeweiligen Angebot. Die Angebote auf der Website sowie in anderen Werbemedien der Firma TicTrack UG (haftungsbeschränkt) sind unverbindlich (invitatio ad offerendum). Zum Vertragsschluss ist die Unterzeichnung des Auftragsverarbeitungsvertrages notwendig.</p>
                <p>(5) Das Vertragsverhältnis kommt erst mit der erfolgreich abgeschlossenen Registrierung des Kunden zustande, wobei die Annahme mit der Freischaltung des Kundenkontos erfolgt.</p>
                <p>(6) Der Kunde hat einmalig das Recht, 14 Tage lang ein Paket des Produktes kostenlos zu testen und zu nutzen (Probezeit). Sollten nach Ablauf der Testphase keine Zahlungsdaten eingegeben worden sein, wird der Zugriff auf den Account verwehrt und der nach drei Monaten gelöscht.</p>        
                <p>(7) Insbesondere der Zugriff durch Administratoren und Mitarbeiter auf die Zeiterfassung ist bis zur kostenpflichtigen Buchung eines neuen Paketes nicht möglich. Bei einem Wechsel des Abonnement-Pakets während der Testphase verfällt die aktuelle Testphase und es besteht kein Recht auf eine weitere Testphase. Alle Daten des Unternehmens (insbesondere zeiterfassungsbezogene Daten) werden nach Ablauf des Paketes drei Monate lang gespeichert und können bei erneuter Buchung eines kostenpflichtigen Paketes wieder abgerufen werden.</p>
                <p>(8) Nach Ablauf dieser Frist ohne Zahlung werden die zeiterfassungsbezogenen Daten des Accounts unwiederbringlich gelöscht. Gleicher Prozess gilt bei ausbleibenden Zahlungen durch den Kunden während einer gebuchten Paket-Laufzeit, sowie bei Kündigung. Die TicTrack UG (haftungsbeschränkt) kann dem Kunden eine verlängerte Aufbewahrungsfrist der Daten einräumen.</p>
                <h3>§ 4 Support und Kommunikation mit dem Anbieter</h3>
                <p>Der Kunde stimmt der elektronischen Kommunikation mit dem Anbieter in Bezug auf die Nutzung des Dienstes zu, einschließlich jeglicher rechtlichen Kommunikation. Wenn der Kunde seine Zustimmung widerrufen möchten oder Fragen zur Nutzung des Dienstes oder zu diesen Bedingungen hat, ist der Anbieter unter info@tictrack.de zu kontaktieren.</p>
                <h3>§ 5 Service Levels; Störungsbehebung</h3>
                <p>(1) Der Anbieter gewährt eine Gesamtverfügbarkeit der Leistungen von mindestens 99 % im Monat am Übergabepunkt. Der Übergabepunkt ist der Routerausgang des Rechenzentrums des Anbieters.</p>
                <p>(2) Als Verfügbarkeit gilt die Möglichkeit des Kunden sämtliche Hauptfunktionen der Plattform zu nutzen. Wartungszeiten sowie Zeiten der Störung unter Einhaltung der Behebungszeit gelten als Zeiten der Verfügbarkeit der Plattform. Zeiten unerheblicher Störungen bleiben bei der Berechnung der Verfügbarkeit außer Betracht. Für den Nachweis der Verfügbarkeit sind die Messinstrumente des Anbieters im Rechenzentrum maßgeblich.</p>
                <p>(3) Schwerwiegende Störungen (die Nutzung der Plattform insgesamt oder eine Hauptfunktion der Plattform ist nicht möglich) wird der Anbieter auch außerhalb der Servicezeiten spätestens binnen 2 Stunden ab Eingang der Meldung der Störung – sofern die Meldung innerhalb der Servicezeiten erfolgt – beheben (Behebungszeit). Sofern absehbar ist, dass eine Behebung der Störung nicht innerhalb dieser Zeitspanne möglich ist, wird er dem Kunden hierüber unverzüglich informieren und die voraussichtliche Überschreitung der Zeitspanne mitteilen.</p>
                <p>(4) Die Beseitigung von unerheblichen Störungen liegt im Ermessen des Anbieters.</p>
                <p>(5) Etwaige sonstige gesetzliche Ansprüche des Kunden gegen den Anbieter bleiben unberührt.</p>
                <h3>§ 6 Pflichten des Kunden</h3>
                <p>(1) Der Kunde hat die ihm übermittelten Zugangsdaten dem Stand der Technik entsprechend vor Zugriffen Dritter zu schützen und zu verwahren. Der Kunde wird dafür sorgen, dass eine Nutzung nur im vertraglich vereinbarten Umfang geschieht. Ein unberechtigter Zugriff ist dem Anbieter unverzüglich mitzuteilen.</p>
                <p>(2) Der Kunde teilt dem Anbieter jegliche wesentliche Änderung der Kundendaten mit; das gilt insbesondere für Änderungen bezüglich der Firmierung und Adresse.</p>
                <p>(3) Der Kunde ist verpflichtet, korrekte, umfassende und aktuelle Rechnungsdaten anzugeben und diese zu aktualisieren, wenn sie sich aus irgendeinem Grund ändern. Der Anbieter behält sich vor, diese Informationen auch bei kostenlosen Testversionen aus verfahrenstechnischen Gründen zu erheben. Zudem behält der Anbieter sich vor, eine Kopie des gültigen amtlichen Lichtbildausweises und gegebenenfalls eine Kopie eines aktuellen Kontoauszugs für die für die Buchung verwendete Kredit- oder Debitkarte zu verlangen.</p>
                <p>(4) Die Zugriffssoftware und der benötigte Speicherplatz werden über das Internet zur Verfügung gestellt. Der Internetzugang sowie die Datensicherung sind nicht Bestandteil der Leistungen. Der Kunde ist für die Datensicherung selbst verantwortlich. Darüber hinaus wird eine kostenlose App zur Verfügung gestellt, dafür ist ein internetfähiges Smartphone notwendig.</p>
                <h3>§ 7 Gewährleistung</h3>
                <p>(1) Hinsichtlich der Gewährung der Nutzung der Plattform gilt das Mietrecht ( §§ 535 ff. BGB).</p>
                <p>(2) Der Kunde hat dem Anbieter jegliche Mängel unverzüglich anzuzeigen.</p>
                <p>(3) Die Gewährleistung für nur unerhebliche Minderungen der Tauglichkeit der Leistung wird ausgeschlossen. Die verschuldensunabhängige Haftung gem. § 536a Abs. 1 BGB für Mängel die bereits bei Vertragsschluss vorlagen ist ausgeschlossen.</p>
                <p>(4) Der Anbieter gewährleistet nicht die Funktionsfähigkeit und Störungsfreiheit der kooperierenden Drittanbieter. Der Kunde hat sich wegen etwaiger Ansprüche an diese zu wenden. Über diese AGB hinaus gelten die entsprechenden Nutzungsbedingungen der kooperierenden Unternehmen bezüglich der Plattformen.</p>
                <h3>§ 8 Haftung</h3>
                <p>(1) Die Parteien haften unbeschränkt bei Vorsatz, grober Fahrlässigkeit sowie bei schuldhafter Verletzung von Leben, Körper oder Gesundheit.</p>
                <p>(2) Unbeschadet der Fälle unbeschränkter Haftung gemäß § 8 (1) haften die Parteien einander bei leicht fahrlässiger Pflichtverletzung nur bei Verletzung wesentlicher Vertragspflichten, also Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht oder deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung die andere Partei regelmäßig vertrauen darf, allerdings beschränkt auf den bei Vertragsschluss vorhersehbaren, vertragstypischen Schaden.</p>
                <p>(3) Die vorstehenden Haftungsbeschränkungen gelten nicht für die Haftung nach dem Produkthaftungsgesetz sowie im Rahmen schriftlich von einer Partei übernommene Garantien.</p>
                <p>(4) Dieser Paragraf gilt auch zu Gunsten von Mitarbeitern, Vertretern und Organen der Parteien.</p>
                <h3>§ 9 Vergütungs- und Zahlungsbedingungen</h3>
                <p>(1) Der Anbieter bietet die Leistungen auf Grundlage von Abonnementmodellen an, mit Ausnahme der kostenlosen Testversion, die für eine begrenzte Zeit kostenlos ist.</p>
                <p>(2) Ein Abonnement beginnt, wenn der Kunde aktiv von einer kostenlosen Testversion zu einem Abonnementmodell wechselt, nach Ablauf der Testphase noch nicht gekündigt hat oder direkt ein solches bucht. Die Abrechnung des Abonnements erfolgt im Voraus, jeweils nach dem vereinbarten Turnus. Die Rechnungen von TicTrack UG (haftungsbeschränkt) sind jeweils ohne Abzug binnen 2 (zwei) Wochen zu begleichen. Dem Kunden werden Rechnungen elektronisch gemäß § 14 Abs. 1 UstG übermittelt.</p>
                <p>(3) Eine nicht vollständige und/oder nicht rechtzeitige Zahlung kann zu einer Sperrung oder Kündigung des Kontos und zur Löschung der Daten führen.</p>
                <p>(4) Der Anbieter verwendet einen Drittanbieter-Zahlungsdienst, um die Rechnungen zu stellen, der zusätzlich zu diesen Bedingungen eigene Bedingungen haben kann. Der Anbieter ist nicht verantwortlich für Störungen im Zahlungsvorgang, die durch den Zahlungsdienst hervorgerufen wurden. Sollte die Bezahlung über den Drittanbieter-Zahlungsdienst nicht gewünscht sein, werden der Anbieter und der Kunde eine Individualvereinbarung über die Zahlungsmodalitäten schließen.</p>
                <h3>§ 10 Vertragslaufzeit und Beendigung</h3>
                <p>(1) Der Vertrag gilt ohne besondere Vereinbarung unbefristet, es sei denn es ist eine bestimmte Laufzeit vereinbart.</p>
                <p>(2) Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt unberührt. Ein wichtiger Grund zur Kündigung seitens des Anbieters liegt insbesondere bei missbräuchlichem Erschleichen der kostenlosen Nutzung bei Testphasen durch mehrfaches Erstellen eines neuen Kontos sowie bei mehrfacher Nichtzahlung der Rechnung nach Mahnung vor.</p>
                <p>(3) Nach Vertragsschluss zum Probe-Paket ist die Kündigung (per E-Mail oder Post) des Vertrages für beide Vertragsparteien mit einer Frist von 5 Werktagen zum Ende der jeweiligen Laufzeit möglich. Die Kündigung wird erst mit ihrem Zugang wirksam. Gleichermaßen behandelt wird die Kündigung von kostenpflichtigen Paketen. Jene wird jedoch auch durch die Kündigung über die Schaltfläche “Plan kündigen” im Stripe Kundenportal zum Ende des Abrechnungszeitraums wirksam.</p>
                <p>(4) Die Nutzungsrechte bleiben für den Rest des bezahlten Intervalls bestehen und verfallen anschließend.</p>
                <h3>§ 11 Datenschutz; Geheimhaltung</h3>
                <p>(1) Es werden die jeweils geltenden anwendbaren datenschutzrechtlichen Bestimmungen eingehalten.</p>
                <p>(2) Sofern und soweit der Anbieter im Rahmen der Leistungserbringung Zugriff auf personenbezogene Daten des Kunden hat, werden die entsprechenden personenbezogenen Daten allein nach den dort festgehaltenen Bestimmungen und nach den Weisungen des Kunden verarbeitet.</p>
                <p>(3) Sofern die Vertragsparteien im Rahmen dieses Vertrages vertrauliche Informationen austauschen, werden sie sicherstellen, dass Dritten ein unbefugter Zugriff auf derartige Informationen nicht möglich ist. Diese Verpflichtungen bleiben auch nach Vertragsbeendigung bestehen. Vertrauliche Informationen sind dabei solche, die als vertraulich gekennzeichnet sind oder deren Vertraulichkeit sich aus den Umständen ergibt, unabhängig davon, ob sie in schriftlicher, elektronischer, verkörperter oder mündlicher Form mitgeteilt worden sind. Die Vertragsparteien werden derartige Informationen ausschließlich im Sinne des vorliegenden Vertrages nutzen.</p>
                <p>(4) Eine Weitergabe von vertraulichen Informationen an Dritte ist nur zulässig, soweit es zur Erfüllung dieses Vertrages unerlässlich ist. In diesem Fall muss die weitergebende Vertragspartei vor Weitergabe eine schriftliche Vertraulichkeitsverpflichtung des Dritten einholen. Der Umfang dieser Vertraulichkeitsvereinbarung muss dem hier niedergelegten Standard entsprechen.</p>
                <p>(5) Die Geheimhaltungsverpflichtung gilt nicht, soweit eine Partei gesetzlich oder aufgrund bestands- bzw. rechtskräftiger Behörden- oder Gerichtsentscheidung zur Offenlegung der vertraulichen Information verpflichtet ist.</p>
                <h3>§ 12 Schlussbestimmungen</h3>
                <p>(1) Sollten einzelne Regelungen dieses Vertrags unwirksam oder nicht durchführbar sein, bleibt die Wirksamkeit der übrigen Regelungen hiervon unberührt. Die Parteien werden solche Regelungen durch wirksame und durchführbare Regelungen ersetzen, die dem Sinn und wirtschaftlichen Zweck sowie dem Willen der Parteien bei Vertragsschluss möglichst gleichkommen. Entsprechendes gilt im Falle einer Vertragslücke.</p>
                <p>(2) Mündliche oder schriftliche Nebenabreden zu diesem Vertrag bestehen nicht. Änderungen dieses Vertrags und seiner Anlagen bedürfen der Schriftform.</p>
                <p>(3) Es gilt deutsches Recht unter Ausschluss der kollisionsrechtlichen Bestimmungen und des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenverkauf vom 11.4.1980 (UN-Kaufrecht).</p>
                <p>(4) Der Gerichtsstand ist der Sitz der TicTrack UG (haftungsbeschränkt).</p>
            </div>
            <Footer />
            <CookieBanner />
        </>
    )
}

export default Agb;