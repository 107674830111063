import "./CancelButton.css";
import Button from "react-bootstrap/Button";

function CancelButton(props) {
    return (
        <Button className="cancel-button" variant="light" onClick={props.onClick}>{props.text}</Button>
    )
}

export default CancelButton;
