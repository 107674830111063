import "./ResetPassword.css";
import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";
import Passwordcheck from "../sharedcomponents/Passwordcheck/Passwordcheck";
import PrimaryButton from "../sharedcomponents/primaryButton/PrimaryButton";

function ResetPassword() {

    const [sendToNewPasswordSuccessful, setSendToNewPasswordSuccessful] = useState(false);
    const [sendToNewPasswordError, setSendToNewPasswordError] = useState(false);
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const[initialRender, setInitialrender] = useState(true) //disable call of this useEffect on first render

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)
        } else {
            sendRequestToChangePassword();
        }
    }, [password]);

    return (
        <>
            <div className="password-reset-container">
                <h3>TicTrack</h3>
                {!sendToNewPasswordSuccessful && <h5>Bitte geben Sie nun eine neues sicheres Passwort ein.</h5>}
                {sendToNewPasswordSuccessful && <div className="reset-password-change-password-successful">
                    <h4>Ihr Passwort wurde erfolgreich geändert.</h4>
                    <div className="go-to-login-button-container">
                        <PrimaryButton text="Zur Anmeldung" onClick={() => navigate("/login")}/>
                    </div>
                </div>}
                {!sendToNewPasswordSuccessful && <Passwordcheck giveNewPassword={newPassword => setPassword(newPassword)}    
                    buttonText = "Neues Passwort festlegen"  />}

                {sendToNewPasswordError &&<div className="reset-password-change-password-error">
                    <p>Ein Fehler ist aufgetreten. Prüfen Sie Ihre Eingaben und versuchen Sie es erneut.</p>
                </div>}
            </div>
            <Footer />
            <CookieBanner/>
        </>
    )

    function sendRequestToChangePassword() {
        axios.put('account/changePassword/setNewPassword', {
            "reset_token": new URLSearchParams(window.location.search).get("token").toString(),
            "new_password": password
        }).then(() => {
            setSendToNewPasswordSuccessful(true);
        }).catch(() => {
            setSendToNewPasswordError(true);
        })
    }
}

export default ResetPassword;