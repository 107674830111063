import "./AdminController.css";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import {useEffect, useState} from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import { 
    Dialog, DialogContent, 
    DialogTitle, Button, 
    FormControl
} from "@mui/material";
import Passwordcheck from "../../../sharedcomponents/Passwordcheck/Passwordcheck";


function AdminController() { 
    //TODO hover Question mark with more information for User regarding that particular field

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
        resetConst();
    }
    const handleClose = () => setShow(false);

    const [usernameBlank, setUsernameBlank] = useState(false)
    const [nameBlank, setNameBlank] = useState(false)

    const [password, setPassword] = useState("")
    const [checkPasswordForm, setCheckPasswordForm] = useState(false)

    const [correctUsername, setCorrectUsername] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        setCheckPasswordForm(true);
        if(document.getElementById("AdministratorUsername").value === "") setUsernameBlank(true)
        if(document.getElementById("AdministratorName").value === "") setNameBlank(true)
    }

    const[initialRender, setInitialrender] = useState(true) //disable call of this useEffect on first render

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)
        } else {
            sendPostRequest();
        }
    }, [password]);

    return (
        <>
            <div className="admin-controller-setting-header">
                <h3>Admin-Accounts</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Legen Sie hier die Admin-Accounts für die Mitarbeiter an, die Zugriff auf die Admin Ansicht der Zeiterfassung haben sollen.</h5>
            <h5 className="setting-info-text gray-text">Admins haben keinen Zugriff auf diese Seite.</h5>
            <ChangeSettingButton buttonText="Admin hinzufügen" onClick={handleShow}/>
            {createModal()}
        </>
    )

    function createModal() {
        return (
            <Dialog open={show} onClose={handleClose} aria-labelledby="dialog-title">
                <DialogTitle id="dialog-title" fontWeight={'bold'} fontSize={30}>Administrator hinzufügen</DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>      
                        <FormControl sx={{width: '98%'}}>
                            <TextField                                 
                                autoFocus 
                                error={usernameBlank} 
                                required 
                                margin="normal" 
                                id="AdministratorUsername" 
                                label="Benutzername" 
                                type="text"                             
                                variant="standard"
                                onChange={e => setUsernameBlank(e.target.value === "")}
                                helperText={usernameBlank ? "Benutzername ist leer.": false}
                            />
                        </FormControl>
                        <div className="create-admin-requirements">
                            {!correctUsername && <p className="red">Die Anforderungen sind nicht erfüllt.</p>}
                            <p>- 3-30 Zeichen</p>
                            <p>- Nur Buchstaben und Ziffern</p>
                            <p>- Folgende Sonderzeichen sind erlaubt: !#*+-._</p>
                            <p>- Keine Leerzeichen</p>
                        </div>

                        <FormControl sx={{width: '98%'}}>
                            <TextField 
                                error={nameBlank} 
                                required 
                                margin="normal" 
                                id="AdministratorName" 
                                label="Name" 
                                type="text"                             
                                variant="standard" 
                                onChange={e => setNameBlank(e.target.value === "")}
                                helperText={nameBlank ? "Name ist leer.": false}
                            />
                        </FormControl>
                        <Button type="submit" hidden/>
                    </form>
                    <Passwordcheck 
                        buttonText= "Admin erstellen"
                        giveNewPassword={newPassword => setPassword(newPassword)} 
                        closeModal={handleClose} 
                        extraFormValidation={handleSubmit} 
                        activateFormValidation={checkPasswordForm} 
                        setActivateFormValidation={setCheckPasswordForm}
                    />    
                </DialogContent>
            </Dialog>
        )
    }

    function checkUsername() {
        let username = document.getElementById("AdministratorUsername").value;
        let regex = /^[a-zA-Z0-9!#*+-._]+$/;
        if (regex.test(username) && username.length >= 3 && username.length < 31) {
            return true;
        } else {
            setCorrectUsername(false);
            resetConst();
            return false;
        }
    }

    function sendPostRequest() {
        if (checkUsername()) {
            axios.post('administrator/create', {
                "username": document.getElementById("AdministratorUsername").value,
                "name": document.getElementById("AdministratorName").value,
                "password": password
            }).then(() => {
                handleClose();
                window.location.reload();
            }).catch(() => {
                if(document.getElementById("AdministratorUsername").value === "") setUsernameBlank(true)
                if(document.getElementById("AdministratorName").value === "") setNameBlank(true)
            }) 
        } 
    }

    function resetConst(){
        setUsernameBlank(false)
        setNameBlank(false);
        setPassword("");
    }
}

export default AdminController;