import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router";
import "./ValidateNewMail.css";
import Footer from "../../footer/Footer";
import CookieBanner from "../../sharedcomponents/CookieBanner";

function ValidateNewMail() {

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        axios.put('account/changeEmail/validateNewMail', {
            "new_mail_verification_token": new URLSearchParams(window.location.search).get("token").toString(),
        }).then(() => {
            localStorage.setItem("changing_mail", "Succesful");
            navigate('/login');
        }).catch(() => {
            setErrorMessage(true);
        })
    }, [])

    return (
        <>
            {errorMessage && <div className="error-mail-validation-message">
                <h2>TicTrack</h2>
                <h3>Ein Fehler ist aufgetreten. Bitte probieren Sie den Vorgang erneut.</h3>
            </div>}
            <Footer />
            <CookieBanner/>
        </>
    )
}

export default ValidateNewMail;