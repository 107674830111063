import "./Payment.css";
import Abonnement from "./abonnement/Abonnement";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import Logout from '../../sharedcomponents/Logout'
import AskForAccess from "../../sharedcomponents/AskForAccess";

function Payment() {

    const [hasActiveSubsciption, setActiveSubscription] = useState(false);
    const [currentPlan, setCurrentPlan] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getAccountData();
    }, [])

    function getAccountData() {
        axios.get('account/getInfo').then(results => {
            setActiveSubscription(results.data.has_active_subscription);
            setCurrentPlan(results.data.current_plan);
            AskForAccess();
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    return isLoading ? 
        <LoadingSpinner/>
        :
        <div className="payment-settings-container">
            <Abonnement hasActiveSubsciption={hasActiveSubsciption} currentPlan={currentPlan} />
        </div>
}

export default Payment;