import "./AdminAccounts.css";
import AdminController from "./controller/AdminController";
import AdminList from "./list/AdminList";
import {useEffect} from "react";
import axios from "axios";
import AskForAccess from "../../sharedcomponents/AskForAccess";

function AdminAccounts() {
    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        AskForAccess();
    })

    return (
        <div className="admin-accounts-settings-container">
            <AdminController />
            <AdminList />
        </div>
    )
}

export default AdminAccounts;