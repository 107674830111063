import CookieConsent from "react-cookie-consent";

function CookieBanner(){
    return (
        <CookieConsent
            //debug={true}
            location="bottom"
            buttonText="Ich verstehe"
            cookieName="acceptedTechnicalCookies"
            >
                Diese Webseite nutzt technisch notwendige Cookies
        </CookieConsent>
    )
}

export default CookieBanner;