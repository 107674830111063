import "./ChangeMail.css";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import { Modal} from "react-bootstrap";
import axios from "axios";
import {useState} from "react";
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";
import CancelButton from "../../../sharedcomponents/cancelButton/CancelButton";

function ChangeMail(props) {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [isChangingMailSuccessful, setIsChangingMailSuccessful] = useState(false);

    return (
        <>
            <div className="change-mail-setting-header">
                <h3>E-Mail ändern</h3>
                <h3 className="gray-text">{props.email}</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Ihre E-Mail wird zum Einloggen auf dieser Seite benötigt. Im Regelfall brauchen nur Sie Zugriff auf diese Seite.</h5>
            {isChangingMailSuccessful && <div className="successful-changing-mail-message">
                <p>Es wurde erfolgreich eine E-Mail an {sessionStorage.getItem("Mail").toString()} gesendet.</p>
            </div>}
            <ChangeSettingButton buttonText="E-Mail ändern" onClick={handleShow}/>
            {createModal()}
        </>
    )

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>E-Mail ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form-element">
                        <p>Möchten Sie wirklich Ihre E-Mail Adresse ändern?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Ja" onClick={sendPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function sendPostRequest() {
        axios.put('account/changeEmail/initialRequest').then(() => {
            handleClose();
            setIsChangingMailSuccessful(true);
        }).catch(() => {

        })
    }
}

export default ChangeMail;