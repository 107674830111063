import "./LoginForm.css";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import { TextField, Button, InputAdornment, IconButton, Snackbar, Alert } from "@mui/material";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelButton from "../../../sharedcomponents/cancelButton/CancelButton";
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";
import { Modal } from "react-bootstrap";

function LoginForm() {
    const navigate = useNavigate();

    const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false)
    const [passwordEmpty, setPasswordEmpty] = useState(false)

    const [showMailSuccessfulSendingMessage, setShowMailSuccessfulSendingMessage] = useState(false);
    const [showSuccessAccountCreation, setShowSuccessAccountCreation] = useState(false);
    const [showSuccessChangingMail, setShowSuccessChangingMail] = useState(false);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [forgetPasswordMail, setForgetPasswordMail] = useState('');

    const [passwordShown, setPasswordShown] = useState(false)
    const handlePasswordShow = () => setPasswordShown(!passwordShown);

    const handleSubmit = (event) => {
        event.preventDefault();
        setEmailEmpty(email === "")
        setPasswordEmpty(document.getElementById("PasswordLoginInput").value === "")

        if(!emailEmpty && !passwordEmpty){
            setIsLoading(true);
            logIn();
        }
    }

    useEffect(() => {
        if (localStorage.getItem("creating_new_account") === "Succesful")
            setShowSuccessAccountCreation(true);
        if (localStorage.getItem("changing_mail") === "Succesful")
            setShowSuccessChangingMail(true);
    }, [])

    const handleForgetPasswordMailInputChange = (event) => {
        setForgetPasswordMail(event.target.value);
    };

    const handlePasswortForgetSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (document.getElementById("PasswordForgetMail") !== null) {
            axios.put('account/changePassword/initialRequest', {
                "email_address" : forgetPasswordMail
            })
            handleClose();
            setShowMailSuccessfulSendingMessage(true);
            setIsLoading(false);
        }
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
        setEmailEmpty(event.target.value === "")
    }

    function getErrorMessage() {
        if (showLoginErrorMessage) {
            return (
                <div className="login-error-message">
                    <p>Fehler bei der Anmeldung. Prüfe deine Eingaben und probiere es erneut.</p>
                </div>
            )
        }
    }

    return isLoading ?
        <LoadingSpinner/>
        :
        <>
            {showMailSuccessfulSendingMessage && <div className="successful-reset-password-message">
                <p>Wenn ein Account mit dieser E-Mail besteht, wurde erfolgreich eine E-Mail an diese gesendet.</p>
            </div>}
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showSuccessAccountCreation} autoHideDuration={6000} onClose={() => setShowSuccessAccountCreation(false)}>
                <Alert onClose={() => setShowSuccessAccountCreation(false)} severity="success" sx={{ width: '100%' }}>
                    Sie haben erfolgreich Ihren TicTrack Account erstellt. Bitte melden Sie sich an.
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showSuccessChangingMail} autoHideDuration={6000} onClose={() => setShowSuccessChangingMail(false)}>
                <Alert onClose={() => setShowSuccessChangingMail(false)} severity="success" sx={{ width: '100%' }}>
                    Sie haben erfolgreich Ihre E-Mail geändert.
                </Alert>
            </Snackbar>
            <> 
                <Form className="login-form-container" onSubmit={handleSubmit}>
                    <TextField 
                        error={emailEmpty}
                        sx={{ width:'90%'}} 
                        id="MailLoginInput" 
                        type="email" 
                        label="E-Mail Adresse" 
                        variant="outlined" 
                        value={email} 
                        onChange={handleEmail} 
                    />
                    <TextField 
                        error={showLoginErrorMessage}
                        sx={{ width:'90%'}}
                        id="PasswordLoginInput"
                        type={passwordShown ? "text" : "password"}  
                        label="Passwort" 
                        variant="outlined" 
                        onChange={e => setPasswordEmpty(e.target.value === '')}
                        InputProps={{ 
                            endAdornment: 
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordShow}
                                    edge="end"
                                >
                                {passwordShown ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>                                
                        }}
                    />
                    <div className="login-adornments">
                        <a onClick={handleShow} className="link-forgot-password">Passwort vergessen</a>
                    </div>  
                    <Button className="login-button" variant="contained" onSubmit={handleSubmit} type="submit">
                        Anmelden
                    </Button>
                </Form>
            </>
            {getErrorMessage()}
            {createLinkForgetModal()}
        </>
    

    function logIn() {
        const headers = {
            "username": document.getElementById("MailLoginInput").value,
            "password": document.getElementById("PasswordLoginInput").value
        }

        axios.post('login', null, {headers}).then(result => {
            localStorage.setItem("jwtToken", result.data.access_token);
            localStorage.removeItem("creating_new_account");
            localStorage.removeItem("changing_mail");
            navigate('/settings/home/');
        }).catch(() => {
            setIsLoading(false);
            setShowLoginErrorMessage(true);
        })
    }

    function createLinkForgetModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Passwort vergessen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Bitte geben Sie ihre gülige E-Mail Adresse ein.</p>
                    <form onSubmit={handlePasswortForgetSubmit} className="forget-password-form">
                        <TextField id="PasswordForgetMail" onChange={handleForgetPasswordMailInputChange} className="forget-password-mail" label="E-Mail" variant="outlined" />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Passwort zurücksetzen" onClick={handlePasswortForgetSubmit} />
                </Modal.Footer>
            </Modal>
        )
    }
}

export default LoginForm;