import "./AdminItem.css";

function AdminItem(props) {
    return (
        <div className="admin-container">
            <div className="admin-information-container">
                <img className="neutral-user-icon" src={process.env.PUBLIC_URL + '/icons/user.png'} alt="Benutzer-Icon"/>
                <h5>{props.name} <span className="gray-text">({props.username})</span></h5>
            </div>
            <div className="design-to-click-to-admin-detail-view">
                <img src={process.env.PUBLIC_URL + '/icons/forward.png'}/>
            </div>
        </div>
    )
}

export default AdminItem;