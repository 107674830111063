import "./LoadingSpinner.css";
import {Spinner} from "react-bootstrap";

function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <Spinner className="spinner" animation="border" variant="primary"/>
        </div>
    )
}

export default LoadingSpinner;