import "./Home.css";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import { CardContent } from "@mui/material";
import { useNavigate } from "react-router";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Logout from '../../sharedcomponents/Logout'
import AskForAccess from "../../sharedcomponents/AskForAccess";
import { Link } from "react-router-dom";

function Home() {

    const [hasActiveSubsciption, setActiveSubscription] = useState(false);
    const [registrationProgress, setRegistrationProgress] = useState(0)
    const [circularProgressLabel, setCircularProgressLabel] = useState("0/2")
    const [hasAdminAccount, setHasAdminAccount] = useState(false)
    const [hasQRAccount, setHasQRAccount] = useState(false);
    const [hasTrial, setHasTrial] = useState(false);
    const [trialDate, setTrialDate] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getAccountData();
    }, [])

    function getAccountData() {
        axios.get('account/getInfo').then(results => {
            setActiveSubscription(results.data.has_active_subscription);
            let hasTrial = results.data.trial_information.has_trial;
            setHasTrial(hasTrial);
            if (hasTrial) 
                getFormattedTrialDate(results.data.trial_information.trial_end_timestamp);
            if (results.data.onboarding_status.has_created_admin_account && results.data.onboarding_status.has_created_qr_account) {
                setRegistrationProgress(100)
                setCircularProgressLabel("2/2")
                setHasAdminAccount(true)
                setHasQRAccount(true)
            } else if(results.data.onboarding_status.has_created_admin_account){
                setRegistrationProgress(50)
                setCircularProgressLabel("1/2")
                setHasAdminAccount(true)
            } else if(results.data.onboarding_status.has_created_qr_account){
                setRegistrationProgress(50)
                setCircularProgressLabel("1/2")
                setHasQRAccount(true)
            }
            AskForAccess();
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    function getFormattedTrialDate(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        setTrialDate(formattedDate);
    }

    function abonnementButton(){
        return (
            <Box className="dashboard-container">
                <Card className="dashboard-card" sx={{ backgroundColor:'#f0f0f0', minWidth: '30%', boxShadow: 'none', borderRadius: '3em', ":hover":{backgroundColor: '#E3E3E3'}}}>
                    <CardContent>
                        
                        <h5 className="box-header">Einrichten des Company-Acounts</h5>
                        <div className="inner-box-flex">
                            <ul>
                                <li>{!hasAdminAccount ? <Link to={"/settings/adminaccounts/"} className="inner-box-link" >Erstellen eines Admin-Zugangs <i className="arrow"></i></Link> : <del>Erstellen eines Admin-Zugangs</del>}</li>
                                <li>{!hasQRAccount ? <Link to={"/settings/timetracking/"}  className="inner-box-link" >Festlegen der Zugangsdaten für die QR-Tablets <i className="arrow"></i></Link> : <del>Festlegen der Zugangsdaten für die QR-Tablets</del>}</li>
                            </ul>
                            <CircularProgress className="circle-process" variant="determinate" size="5em" thickness={8} value={registrationProgress} sx={{color: '#58726f'}}/>
                            <Box
                                className="label-box"
                                sx={{
                                top: 0,
                                left: '82%',
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold'
                                }}
                            >
                                <p>{circularProgressLabel}</p>
                            </Box>
                            {/* <h6 className="circle-label">{circularProgressLabel}</h6> */}
                        </div>
                        
                        {/* <Box
                            sx={{
                            top: '1em',
                            left: '4em',
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            fontSize: 20, 
                            }}
                        >
                            {circularProgressLabel}
                            <Box
                                sx={{
                                    marginLeft: '0em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: 20
                                }}
                            >
                                <ul>
                                    <li>1. Schritt: Erstellen Sie Adminaccounts</li>
                                    <li>2. Schritt: Richten Sie ihren QR-Login ein</li>
                                </ul>
                            </Box>
                        </Box>
                        <CircularProgress variant="determinate" value={registrationProgress} thickness={8} size='10em' /> */}
                        
                    </CardContent>                            
                </Card>                        
            </Box>             
        )
    }

    return isLoading ?
        <LoadingSpinner/>
        :
        <>
            <div className="home-settings-container">
                <h3>Dashboard</h3>
                <hr className="divider-solid-home"/>
                {hasTrial && <div className="home-test-stage-info">
                    <h5>Sie befinden sich aktuell in der Testphase (Läuft ab am {trialDate}).</h5>
                </div>}
                {abonnementButton()}
            </div>     
        </>
        
    
}

export default Home;