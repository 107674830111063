import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import "./Errorpage.css";
import CookieBanner from "../sharedcomponents/CookieBanner";



function Errorpage(){
    return(
        <>
            <header className="error-header"/>
            <div className="error-page-container">
                <img src={process.env.PUBLIC_URL + '/icons/error_icon_darkblue.png'}/>
                <h1> Seite nicht gefunden</h1>
                <Link to={"/login"}> Hier geht es zurück zur Anmeldung</Link>
            </div>
            <Footer />
            <CookieBanner/>
        </>
        
    )
}

export default Errorpage;