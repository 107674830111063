import "./ChangeName.css";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import CancelButton from "../../../sharedcomponents/cancelButton/CancelButton";
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";

function ChangeName(props) {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        sendPostRequest();
    }
    
    return (
        <>
            <div className="change-name-setting-header">
                <h3>Namen ändern</h3>
                <h3 className="gray-text">{props.displayName}</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Der Unternehmensname wird im Admin-Bereich und beim Mitarbeiter-Login angezeigt.</h5>
            <ChangeSettingButton buttonText="Namen ändern" onClick={handleShow}/>
            {createModal()}
        </>
    )

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Namen ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Neuer Name: </Form.Label>
                            <Form.Control id="Name" placeholder="Name" type="text" className="modal-input form-control"/> 
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Namen ändern" onClick={sendPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function sendPostRequest() {
        axios.put('account/changeName', {
            "new_display_name": document.getElementById("Name").value,
        }).then(() => {
            handleClose();
            window.location.reload();
        }).catch(() => {
            document.getElementById("Name").style="box-shadow: 0 0 5px red;";
        })
    }
}

export default ChangeName;