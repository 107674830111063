import "./SettingsNavbar.css";
import {Container, Nav, Navbar, NavbarBrand, NavDropdown} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function SettingsNavbar() {

    const navigate = useNavigate();

    const [clickedNavItem, setClickedNavItem] = useState("home");
    //const [companyName, setCompanyName] = useState("")

    useEffect(() => {
        if (sessionStorage.getItem("currentNavItem") !== null)
            setClickedNavItem(sessionStorage.getItem("currentNavItem"));

        /* axios.get('account/getInfo').then( results => {
            setCompanyName(results.data.display_name)
        }) */
    }, [])

    const handleNavbarClick = (navItem) => {
        setClickedNavItem(navItem);
        sessionStorage.setItem("currentNavItem", navItem);
    }

    return (
        <>
            <base href="/settings/" />
            <Navbar variant="dark" expand="lg" style={{ backgroundColor: 'var(--tictrack-gray-color)' }}>
                <Container>
                    <Link as={Link} to={"home/"} onClick={() => handleNavbarClick("home")} className={`${clickedNavItem === 'home' ? 'clicked' : ''}`}>
                        <NavbarBrand>
                            <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/company.png'} alt="Firmen-Icon"/>
                            {/*companyName*/} Home 
                        </NavbarBrand>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to={"account/"} onClick={() => handleNavbarClick("account")} className={`${clickedNavItem === 'account' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/gear.png'} alt="Zanhrad-Icon"/>
                                Account
                            </Nav.Link>
                            <Nav.Link as={Link} to={"billing/"} onClick={() => handleNavbarClick("billing")} className={`${clickedNavItem === 'billing' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/merchant_account.png'} alt="Händler-Icon"/>
                                Zahlungsinformationen
                            </Nav.Link>
                            <NavDropdown title="Zugriff & Admins" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to={"adminaccounts/"} onClick={() => handleNavbarClick("adminaccounts")} className={`${clickedNavItem === 'adminaccounts' ? 'clicked' : ''} white-nav-text`}>
                                    <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/check.png'} alt="Haken-Icon"/>
                                    Administrator-Accounts
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={"timetracking/"} onClick={() => handleNavbarClick("timetracking")} className={`${clickedNavItem === 'timetracking' ? 'clicked' : ''} white-nav-text`}>
                                    <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/wrench.png'} alt="Werkzeug-Icon"/>
                                    Zeiterfassung konfigurieren
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className="logout-button-link">
                                <Button variant={"outline-light"} onClick={() => window.open("https://app.tictrack.de")}>Zur Zeiterfassung</Button>
                            </Nav.Link>
                            <Nav.Link className="logout-button-link">
                                <Button variant={"danger"} onClick={() => logOut()}>Abmelden</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>)

    function logOut() {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
    }
}

export default SettingsNavbar;