import "./AdminDetailView.css";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "react-bootstrap";
import { 
    Dialog, DialogContent, DialogTitle,
    TextField, InputAdornment, IconButton, 
    DialogActions
} from "@mui/material";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import Passwordcheck from "../../../sharedcomponents/Passwordcheck/Passwordcheck";
import Logout from '../../../sharedcomponents/Logout'
import BackButton from "../../../sharedcomponents/backButton/BackButton";
import CancelButton from "../../../sharedcomponents/cancelButton/CancelButton";
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";

function AdminDetailView() {

    const [username, setUsername] = useState("");
    const [name, setName] = useState("");

    const [password, setPassword] = useState("")

    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const handleShowRemoveModal = () => setShowRemoveModal(true);
    const handleCloseRemoveModal = () => setShowRemoveModal(false);

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);
    const handleCloseChangePasswordModal = () => {
        setPassword("");
        setShowChangePasswordModal(false);
    }

    const [isLoading, setIsLoading] = useState(true);

    const [activeNameEditMode, setActiveNameEditMode] = useState(false);

    const navigate = useNavigate();

    const handleNameSubmit = (event) => {
        event.preventDefault();
        saveNameEdit();
    }

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getAdminData();
    }, [])


    const[initialRender, setInitialrender] = useState(true) //disable call of this useEffect on first render

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)
        } else {
            savePasswordEdit();
        }
    }, [password]);

    function getAdminData() {
        axios.get('administrator/getByUsername?username=' + new URLSearchParams(window.location.search).get("username").toString())
            .then(results => {
                setUsername(results.data.username);
                setName(results.data.name);
                setIsLoading(false);
            }).catch(() => { Logout()})
    }

    function removeAdmin() {
        axios.delete('administrator/delete', {
            data: {'username': username}
        }).then(() => navigate("/settings/adminaccounts/"))
    }

    function saveNameEdit() {
        axios.put('administrator/edit', {
            "username": username,
            "new_display_name": document.getElementById("AdminName").value,
        }).then(() => {
            setUsername(document.getElementById("AdminName").value)
            window.location.reload();
        })
    }

    function cancelNameEdit() {
        document.getElementById("AdminName").value = name;
        setActiveNameEditMode(false);
    }

    function savePasswordEdit() {
        axios.put('administrator/changePassword', {
            "admin_username": username,
            "new_password": password,
        }).then(() => {
            window.location.reload();
        })
    }

    function createUsernameFormGroup() {
        return (
            <form>
                <TextField
                    sx={{width: '40%'}}
                    label="Benutzername"
                    margin="normal"
                    disabled
                    defaultValue={username}
                />
            </form>
        )
    }

    function createNameFormGroup() {
        return (
            <form>
                <TextField
                    sx={{width: '40%'}}
                    id="AdminName"
                    label="Name"
                    margin="normal" 
                    type="text"
                    disabled={!activeNameEditMode}
                    defaultValue={name}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={() => setActiveNameEditMode(true)}>
                                    <EditIcon/>
                                </IconButton>
                            </InputAdornment>   
                    }}    
                />
                {activeNameEditMode &&
                    <div className="admin-detail-view-edit-mode-button-container">
                        <PrimaryButton text="Speichern" onClick={handleNameSubmit} />
                        <CancelButton text="Abbrechen" onClick={cancelNameEdit} />
                    </div>
                }
            </form>
        )
    }

    function createPasswordFormGroup() {
        return (
            <form>
                <TextField 
                    sx={{width: '40%'}}
                    disabled
                    label="Neues Passwort" 
                    margin="normal" 
                    type="text"
                    defaultValue="Hier können Sie ein neues Admin Passwort vergeben"         
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowChangePasswordModal}>
                                    <EditIcon/>
                                </IconButton>
                            </InputAdornment>   
                    }}        
                />
            </form>
        )
    }

    function createForm() {
        return (
            <>
                <div className="detail-view-container">
                    {createUsernameFormGroup()}
                    {createNameFormGroup()}
                    {createPasswordFormGroup()}
                </div>
                {createModalForChangeAdminPassword()}
            </>
        )
    }

    function createModalForRemoving() {
        return (
            <Dialog open={showRemoveModal} onClose={handleCloseRemoveModal} aria-labelledby="remove-dialog-title">
                <DialogTitle id="remove-dialog-title" fontWeight={'bold'} fontSize={24}>Admin löschen</DialogTitle>
                <DialogContent dividers>
                    <div className="modal-form-element">
                        <h5>Möchten Sie den Administrator '{name}' wirklich löschen?</h5>
                    </div>
                </DialogContent>
                <DialogActions>
                    <CancelButton text="Nein" onClick={handleCloseRemoveModal} />
                    <Button variant="danger" onClick={removeAdmin}>Admin löschen</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function createModalForChangeAdminPassword() {
        return (
            <Dialog open={showChangePasswordModal} onClose={handleCloseChangePasswordModal} aria-labelledby="dialog-title">
                <DialogTitle id="dialog-title" fontWeight={'bold'} fontSize={30}>Neues Admin Passwort</DialogTitle>
                <DialogContent dividers>
                    <h5>Achtung!</h5>
                    <h6>Bitte prüfen Sie wirklich, ob Sie ein neues Passwort vergeben müssen. </h6>
                    <h6>Wenn Sie ein neues Passwort vergeben, kann sich der entsprechende Admin nur noch mit dem
                        neuen Passwort anmelden.</h6>

                    <Passwordcheck
                        buttonText= "Speichern"
                        giveNewPassword={newPassword => setPassword(newPassword)}  
                        closeModal={handleCloseChangePasswordModal}      
                    />
                    
                </DialogContent>
            </Dialog>
        )
    }

    function goBackToAdminAccounts() {
        navigate('/settings/adminaccounts/');
    }

    return isLoading ? 
        <LoadingSpinner/>
        :
        <>
            <div className="admin-back-button">
                <BackButton onClick={goBackToAdminAccounts} />
            </div>
            <div className="admin-detail-view-header">
                <h3>{name}</h3>
                <Button variant="danger" onClick={handleShowRemoveModal}>Admin löschen</Button>
            </div>
            <hr className="divider-solid"/>
            {createForm()}
            {createModalForRemoving()}
        </>
    
}

export default AdminDetailView;