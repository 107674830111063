import PasswordChecklist from "react-password-checklist";
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {TextField, InputAdornment, IconButton, DialogActions} from "@mui/material";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ChangeSettingButton from "../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import PrimaryButton from "../primaryButton/PrimaryButton";
import CancelButton from "../cancelButton/CancelButton";

function Passwordcheck(props){

    /**
     *  How to Use: 
     *      if you only need the password fields, then use these functions from props: 
     *          giveNewPassword             //to update the password const in parent component 
     *          closeModal                  //to closeModal with cancel Button
     * 
     *      if you have more Inputfields like username inputs, then use additionally these props: 
     *          extraFormValidation         //messages the parent, that the child triggered a submit and parent has now to check its inputs
     *          activateFormValidation      //indicates the child, that the parent triggered a submit and we have to check the password inputs
     *          setActivateFormValidation   //reset const for future trigger from parent
     */

    const [passwordError, setPasswordError] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const handlePasswordShow = () => setPasswordShown(!passwordShown);

    const [password, setPassword] = useState("") 
    const [passwordAgain, setPasswordAgain] = useState("")
    const [passwordValid, setPasswordValid] = useState(false)

    const handlePasswordSubmit = (event) => {
        event.preventDefault();  
        if(props.extraFormValidation !== undefined) props.extraFormValidation(event);     
        checkPasswordInput();
    }

    const checkPasswordInput = () => {
        if(!passwordValid || password !== passwordAgain) setPasswordError(true);
        if(passwordValid && password === passwordAgain) props.giveNewPassword(password);
        if(props.setActivateFormValidation !== undefined)props.setActivateFormValidation(false);
    }

    const[initialRender, setInitialrender] = useState(true) //disable call of this useEffect on first render

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)            
        } else{
            checkPasswordInput();
        }
        
    }, [props.activateFormValidation]) 

    return(
        <>
            <form onSubmit={handlePasswordSubmit}>
                <TextField 
                    sx={{marginTop: 3, width: '90%'}}
                    error={passwordError}                            
                    margin="normal" 
                    required
                    label="Neues Passwort" 
                    type={passwordShown ? "text" : "password"}  
                    variant="standard" 
                    onChange={e => setPassword(e.target.value)}
                    helperText={passwordError ? "Passwort entspricht nicht Anforderungen.": false}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handlePasswordShow}
                            >
                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>                                
                    }}
                />
                <TextField 
                    sx={{width: '90%'}}
                    error={passwordError}
                    margin="normal" 
                    required
                    label="Passwort nochmal" 
                    type={passwordShown ? "text" : "password"}  
                    variant="standard" 
                    onChange={e => setPasswordAgain(e.target.value)}
                    helperText={passwordError ? "Passwort entspricht nicht Anforderungen.": false}
                />
                <Button type="submit" hidden/>
            </form>

            <div className="password-checklist">
                <PasswordChecklist                            
                    rules={["minLength","capital","lowercase","number","specialChar","match"]}
                    minLength={10}
                    value={password}
                    valueAgain={passwordAgain}
                    messages={{
                        minLength: "Passwort muss mindestens 10 Zeichen haben.",
                        capital: "Passwort muss mindestens 1 Großbuchstabe haben.",
                        lowercase: "Passwort muss mindestens 1 Kleinbuchstaben haben.",
                        number: "Passwort muss mindestens 1 Nummer besitzen.",  
                        specialChar: "Passwort muss mindestens 1 Sonderzeichen besitzen.",                                                                  
                        match: "Passwörter stimmen miteinander überein.",
                    }}
                    onChange={(isValid) => {setPasswordValid(isValid)}}
                />
            </div> 
            <DialogActions>
                    {props.closeModal && <CancelButton text="Abbrechen" onClick={props.closeModal} />}
                    <PrimaryButton text={props.buttonText} onClick={handlePasswordSubmit}/>
            </DialogActions>
        </>
    )
}

export default Passwordcheck