import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import "./TrackingConfiguration.css";

function TrackingConfiguration() {

    const [isLoading, setIsLoading] = useState(true);
    const [trackingHourLimit, setTrackingHourLimit] = useState(12);

    const changingProps = {
        trackingLimit: 0
    }

    useEffect(() => {
        getHourLimit();
    }, [])

    const handleSelect = (event) => {
        setTrackingHourLimit(event.target.value);
        changingProps.trackingLimit = event.target.value;
        sessionStorage.setItem("Changing Settings", JSON.stringify(changingProps));
    }

    function getHourLimit() {
        axios.get('settings/getCurrent').then(results => {
            setTrackingHourLimit(results.data.max_shift_duration);
            setIsLoading(false);
        }).catch(() => { 

        })
    }

    return isLoading ?
        <LoadingSpinner />
        :
        (
            <div className="time-config-wrapper">
                <div className="time-config-container">
                    <img className="info-i-img" src={process.env.PUBLIC_URL + '/icons/info-i.png'} alt="Info i" title="Hier können Sie die maximale Länge der Arbeitszeit festlegen."/>
                    <h5>Maximale Länge der Arbeitszeit:</h5>
                    <FormControl variant="standard" sx={{ m: 1 }}>
                        <InputLabel>Maximale Länge der Arbeitszeit</InputLabel>
                        <Select value={trackingHourLimit} onChange={handleSelect} label="Maximale Länge der Arbeitszeit">
                            <MenuItem value={1}> 1 Stunde </MenuItem>
                            <MenuItem value={2}> 2 Stunden </MenuItem>
                            <MenuItem value={3}> 3 Stunden </MenuItem>
                            <MenuItem value={4}> 4 Stunden </MenuItem>
                            <MenuItem value={5}> 5 Stunden </MenuItem>
                            <MenuItem value={6}> 6 Stunden </MenuItem>
                            <MenuItem value={7}> 7 Stunden </MenuItem>
                            <MenuItem value={8}> 8 Stunden </MenuItem>
                            <MenuItem value={9}> 9 Stunden </MenuItem>
                            <MenuItem value={10}> 10 Stunden </MenuItem>
                            <MenuItem value={11}> 11 Stunden </MenuItem>
                            <MenuItem value={12}> 12 Stunden </MenuItem>
                            <MenuItem value={13}> 13 Stunden </MenuItem>
                            <MenuItem value={14}> 14 Stunden </MenuItem>
                            <MenuItem value={15}> 15 Stunden </MenuItem>
                            <MenuItem value={16}> 16 Stunden </MenuItem>
                            <MenuItem value={17}> 17 Stunden </MenuItem>
                            <MenuItem value={18}> 18 Stunden </MenuItem>
                            <MenuItem value={19}> 19 Stunden </MenuItem>
                            <MenuItem value={20}> 20 Stunden </MenuItem>
                        </Select>
                    </FormControl> 
                </div>
            </div>
        )

}

export default TrackingConfiguration;