import axios from "axios";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import "./AdvancedSettings.css"
import { Button } from "react-bootstrap";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function AdvancedSettings(){
    const [sendOpen, setSendOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [catchOpen, setCatchOpen] = useState(false)

    const handleAVV = () => {
        axios.get('account/agreements/downloadAVVAsPDF',
        {
            responseType: 'blob',
            headers: {'Content-Type': 'application/json'},
        }).then(results => createPDF(results))
    }

    function createPDF(results){
        const data = results.data
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', "AVV");
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleSendData = () => {
        axios.post('account/mailrequest/receiveAllSavedData').then(() => {
            setSendOpen(true)
        }).catch(() => {
            setCatchOpen(true)
        })
    }

    const handleDeleteData = () => {
        setDeleteOpen(false)
        axios.delete('account/mailrequest/deleteAllSavedData').then(() => {
            setSendOpen(true)
        }).catch(() => {
            setCatchOpen(true)
        })
    }

    function sendDataDialog(){
        return (
            <Dialog
                open={sendOpen}
                onClose={()=> setSendOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ihr Antrag wurde erfolgreich versendet"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=> setSendOpen(false)} autoFocus style={{ backgroundColor: 'var(--tictrack-primary-color)' }}>Verstanden.</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function catchDialog(){
        return (
            <Dialog
                open={catchOpen}
                onClose={()=> setCatchOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ihre Anfrage wurde aufgrund eines Fehlers nicht versendet"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={()=> setCatchOpen(false)} autoFocus>Verstanden</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function deleteDataDialog(){
        return (
            <Dialog
                open={deleteOpen}
                onClose={()=> setDeleteOpen(false)}
            >
                <DialogTitle>
                    {"Möchten Sie wirklich Ihre Daten unwiderruflich löschen?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sie schicken eine Anfrage an uns heraus, um ihre Daten unwiderruflich zulöschen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setDeleteOpen(false)}>Abbrechen</Button>
                    <Button variant="danger" onClick={handleDeleteData} autoFocus> Löschen</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return(
        <>
            <div className="advanced-settings-container">
                <h3>AVV </h3>
                <hr className="divider-solid-home"/>
                <h5 className="advanced-settings-h5">Hier können Sie sich den Auftragsverarbeitungsvertrag (AVV) herunterladen</h5>
            </div>
            <ChangeSettingButton buttonText="AVV herunterladen" onClick={handleAVV}/>
            <div className="advanced-settings-container">
                <h3>Datenauskunft beantragen</h3>
                <hr className="divider-solid-home"/>
                <h5 className="advanced-settings-h5">Hier können Sie uns eine Anfrage schicken, Ihnen all Ihre Daten zuschicken</h5>
            </div>
            <ChangeSettingButton buttonText="Datenauskunft beantragen" onClick={handleSendData}/>
            <div className="advanced-settings-container">
                <h3>Datenlöschantrag</h3>
                <hr className="divider-solid-home"/>
                <h5 className="advanced-settings-h5">Hier können Sie uns eine Anfrage schicken, all Ihre Daten unwiderruflich zulöschen</h5>
                <Button style={{marginTop:'2em'}} variant="danger" onClick={() => setDeleteOpen(true)}>Daten unwiderruflich löschen</Button> 
            </div>
            {sendDataDialog()}
            {deleteDataDialog()}
            {catchDialog()}
        </>
        
    )
}

export default AdvancedSettings;