import axios from "axios";

function AskForAccess(){
    axios.get('account/getInfo').then(results => {
        if (!results.data.has_active_subscription) {
            sessionStorage.setItem("access_to_abonnement_page", "Succesful");
            window.location.assign('/chooseAbonnement/');
        } else if (!results.data.onboarding_status.has_created_admin_account) {
            sessionStorage.setItem("access_to_create_initial_admin_page", "Succesful");
            window.location.assign('/createInitialAdminAccount/');
        }
    }).catch(() => { window.location.assign("/settings/home/")})
}

export default AskForAccess;