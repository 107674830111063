import { TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import ChangingPassword from "./changingPassword/ChangingPassword";
import "./TabletConfiguration.css";
import Logout from '../../../sharedcomponents/Logout'
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";
import CancelButton from "../../../sharedcomponents/cancelButton/CancelButton";

function TabletConfiguration() {

    const [isLoading, setIsLoading] = useState(true);

    const [registeredQrTabletLogin, setRegisteredQrTabletLogin] = useState(true);

    const [qrAccountCreatingError, setQrAccountCreatingError] = useState(false);
    const [accountName, setAccountName] = useState("");

    const [pin1, setPin1] = useState(0);
    const [pin2, setPin2] = useState(0);

    // For modal
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getQrAccountInformation();
    }, [])

    function getQrAccountInformation() {
        axios.get('account/getInfo').then(results => {
            if (results.data.onboarding_status.has_created_qr_account) {
                setRegisteredQrTabletLogin(true);
                axios.get('qrAccount/getForCompany').then(results => {
                    setAccountName(results.data.username);
                    setIsLoading(false);
                })
            } else {
                setRegisteredQrTabletLogin(false);
                setIsLoading(false);
            }
        }).catch(() => { Logout()})
    }

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>QR-Tablet - Zugang einrichten</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={createQrAccount} className="create-qr-account-container">
                        <TextField variant="outlined" id="CreateQrAccountName" label="Name des QR-Accounts" />
                        <TextField variant="outlined" type="password" label="4-stelliger Pin des QR-Accounts" onChange={changePin1} />
                        <TextField variant="outlined" type="password" label="Pin wiederholen" onChange={changePin2} />
                        {qrAccountCreatingError && <Typography color="red">Ein Fehler ist aufgetreten. Probieren Sie es erneut.</Typography>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose}/>
                    <PrimaryButton text="Zugang einrichten" onClick={createQrAccount} />
                </Modal.Footer>
            </Modal>
        )
    }

    const changePin1 = (event) => {
        const value = event.target.value;

        const cleanValue = value.replace(/[^\d]/g, '');
        event.target.value = cleanValue;

        if (value.length > 4) {
            event.target.value = pin1;
        } else if(/^\d*$/.test(value)) {
            setPin1(value);
        }
    }

    const changePin2 = (event) => {
        const value = event.target.value;

        const cleanValue = value.replace(/[^\d]/g, '');
        event.target.value = cleanValue;

        if (value.length > 4) {
            event.target.value = pin2;
        } else if(/^\d*$/.test(value)) {
            setPin2(value);
        }
    }

    const createQrAccount = () => {
        let accountName = document.getElementById("CreateQrAccountName").value;

        if (pin1 === pin2) {
            axios.post('qrAccount/create', {
                "username": accountName,
                "password": pin1,
            }).then(() => {
                handleClose();
                window.location.reload();
            }).catch(error => {
                setQrAccountCreatingError(true);
            })
        } else {
            setQrAccountCreatingError(true);
        }
    }

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    } else {
        return registeredQrTabletLogin ?
        (
            <div className="qr-tablet-config-wrapper">
                <div className="qr-tablet-config-container">
                <div className="qr-tablet-config">
                    <img className="info-i-img" src={process.env.PUBLIC_URL + '/icons/info-i.png'} alt="Info i" title="Dies ist Ihr Benutzername für den QR-Account."/>
                    <h5>QR-Nutzername:</h5>
                    <TextField variant="outlined" label="QR-Nutzername" disabled={true} value={accountName} />
                </div>
                <div className="qr-tablet-config">
                    <img className="info-i-img" src={process.env.PUBLIC_URL + '/icons/info-i.png'} alt="Info i" title="Hier haben Sie die Möglichkeit das Passwort des QR-Accounts zu ändern."/>
                    <h5>QR-Passwort ändern:</h5>
                    <ChangingPassword />
                </div>
            </div>
            </div>
        )
    :
        (
            <>
                <div className="qr-tablet-register-wrapper">
                    <div className="qr-tablet-register-container" onClick={handleShow}>
                        <div className="qr-tablet-register-information">
                            <h4>Jetzt QR-Tablets einrichten</h4>
                            <h6>Legen Sie jetzt einmalig die <br/>Zugangsdaten für die QR-Tablets fest.</h6>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/icons/qr.png'} alt="QR-Icon"/>
                        <img className="forward-img" src={process.env.PUBLIC_URL + '/icons/forward-white.png'} alt="QR-Icon"/>
                    </div>
                    {createModal()}
                </div>
            </>
        )
    }
}

export default TabletConfiguration;