import { TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CancelButton from "../../../../sharedcomponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../sharedcomponents/primaryButton/PrimaryButton";
import "./ChangingPassword.css";

function ChangingPassword() {

    const [showSoftPasswordChangingModal, setShowSoftPasswordChangingModal] = useState(false);
    const handleSoftPasswordChangingShow = () => setShowSoftPasswordChangingModal(true);
    const handleSoftPasswordChangingClose = () => setShowSoftPasswordChangingModal(false);
    const [softChangingPasswordError, setSoftChangingPasswordError] = useState(false);

    const [showHardPasswordChangingModal, setShowHardPasswordChangingModal] = useState(false);
    const handleHardPasswordChangingShow = () => setShowHardPasswordChangingModal(true);
    const handleHardPasswordChangingClose = () => setShowHardPasswordChangingModal(false);
    const [hardChangingPasswordError, setHardChangingPasswordError] = useState(false);

    const [pin1, setPin1] = useState(0);
    const [pin2, setPin2] = useState(0);

    const changePin1 = (event) => {
        const value = event.target.value;

        const cleanValue = value.replace(/[^\d]/g, '');
        event.target.value = cleanValue;

        if (value.length > 4) {
            event.target.value = pin1;
        } else if(/^\d*$/.test(value)) {
            setPin1(value);
        }
    }

    const changePin2 = (event) => {
        const value = event.target.value;

        const cleanValue = value.replace(/[^\d]/g, '');
        event.target.value = cleanValue;

        if (value.length > 4) {
            event.target.value = pin2;
        } else if(/^\d*$/.test(value)) {
            setPin2(value);
        }
    }

    const changeSoftPassword = () => {
        if (pin1 === pin2 && pin1.length === 4) {
            axios.put('qrAccount/changePasswordNoJWTKill', {
                "newPassword": pin1,
            }).then(() => {
                handleSoftPasswordChangingClose();
                window.location.reload();
            }).catch(error => {
                setSoftChangingPasswordError(true);
            })
        } else {
            setSoftChangingPasswordError(true);
        }
    }

    const changeHardPassword = () => {
        if (pin1 === pin2) {
            axios.put('qrAccount/changePasswordWithJWTKill', {
                "newPassword": pin1,
            }).then(() => {
                handleHardPasswordChangingClose();
                window.location.reload();
            }).catch(error => {
                setHardChangingPasswordError(true);
            })
        } else {
            setHardChangingPasswordError(true);
        }
    }

    // Change password without logout
    function createLightPasswordChangeModal() {
        return (
            <Modal
                show={showSoftPasswordChangingModal}
                onHide={handleSoftPasswordChangingClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>QR-Account - Pin ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleSoftPasswordChangingClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Typography>Hier haben Sie die Möglichkeit Ihre Pin für Ihren QR-Account zu ändern.</Typography>
                    <Typography>Ihre Tablets bleiben angemeldet.</Typography>
                    <Form onSubmit={changeSoftPassword} className="reset-qr-password-container">
                        <TextField variant="outlined" type="password" label="Neuer 4-stelliger Pin des QR-Accounts" onChange={changePin1} />
                        <TextField variant="outlined" type="password" label="Pin wiederholen" onChange={changePin2}/>
                        {softChangingPasswordError && <Typography color="red">Ein Fehler ist aufgetreten. Probieren Sie es erneut.</Typography>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleSoftPasswordChangingClose}/>
                    <PrimaryButton text="Pin ändern" onClick={changeSoftPassword} />
                </Modal.Footer>
            </Modal>
        )
    }

    // Change password with logout
    function createHardPasswordChangeModal() {
        return (
            <Modal
                show={showHardPasswordChangingModal}
                onHide={handleHardPasswordChangingClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>QR-Account - Pin ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleHardPasswordChangingClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Typography>Hier haben Sie die Möglichkeit Ihre Pin für Ihren QR-Account zu ändern.</Typography>
                    <Typography color="red">Achtung! Die bisher angemelden Tablets werden ausgeloggt!</Typography>
                    <Form onSubmit={changeHardPassword} className="reset-qr-password-container">
                        <TextField variant="outlined" type="password" inputProps={{pattern: "\\d*",}} label="Neuer 4-stelliger Pin des QR-Accounts" onChange={changePin1} />
                        <TextField variant="outlined" type="password" label="Pin wiederholen" onChange={changePin2}/>
                        {hardChangingPasswordError && <Typography color="red">Ein Fehler ist aufgetreten. Probieren Sie es erneut.</Typography>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleHardPasswordChangingClose}/>
                    <PrimaryButton text="Pin ändern!" onClick={changeHardPassword} />
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div className="qr-tablet-change-password-container">
            <Button variant="warning" onClick={handleSoftPasswordChangingShow}>QR-Pin ändern (ohne Logout)</Button>
            <Button variant="danger" onClick={handleHardPasswordChangingShow}>QR-Pin ändern (mit Logout)</Button>
            {createLightPasswordChangeModal()}
            {createHardPasswordChangeModal()}
        </div>
    )
}

export default ChangingPassword;