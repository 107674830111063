import "./Abonnement.css";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import Logout from '../../../sharedcomponents/Logout'
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";

function Abonnement(props) {

    const [checked, setCheck] = useState(false)
    const handleChange = (event) => {
        setCheck(!checked)
    }

    function redirectToStripeCustomerportal(){
        axios.post("stripe/create-customer-portal-session").then(result => {
            window.location.assign(result.data.payment_link);
        }).catch(() => { Logout()})
    }

    function redirectToStripeCheckoutSession(buyOption){
        axios.post("/stripe/create-checkout-session?type=" + buyOption).then(result => {
            window.location.assign(result.data.payment_link);
        }).catch(() => { Logout()})
    }

    function subscriptionButtons(){
        if(props.hasActiveSubsciption === false){ 
            if(checked){
                return(
                    <>
                        <Form className="monthly-switch">
                            <Form.Switch onChange={handleChange} checked={checked} label="Monatlich"/>           
                        </Form>
                        <div className="card-container">
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Small</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">59€ / Monat</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 10 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 2 Standorte</li>
                                            <li className="list-group-item">Bis zu 2 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 5,90€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("SMALL_MONTHLY")}/> 
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Medium</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">129€ / Monat</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 25 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 10 Standorte</li>
                                            <li className="list-group-item">Bis zu 5 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 5,16€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("MEDIUM_MONTHLY")}/>  
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Large</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">219€ / Monat</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 50 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 25 Standorte</li>
                                            <li className="list-group-item">Bis zu 10 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 4,38€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("LARGE_MONTHLY")}/>   
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Extra</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">299 / Monat</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 75 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 50 Standorte</li>                                        
                                            <li className="list-group-item">Bis zu 20 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 3,98€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("EXTRA_MONTHLY")}/>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="subtext">Alle Preise sind Nettopreise und verstehen sich zzgl. der gesetzlichen MwSt.</p>
                        <p className="subtext">*Bei Ausnutzung aller möglichen Mitarbeiter</p>
                    </>                    
                )
            } else {
                return(
                    <>
                        <Form className="monthly-switch">
                            <Form.Switch onChange={handleChange} checked={checked} label="Jährlich"/>           
                        </Form>
                        <div className="card-container">
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Small</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">629€ / Jahr</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 10 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 2 Standorte</li>                                        
                                            <li className="list-group-item">Bis zu 2 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 5,24€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("SMALL_YEARLY")}/>
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Medium</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">1.389€ / Jahr</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 25 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 10 Standorte</li>
                                            <li className="list-group-item">Bis zu 5 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 4,63€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("MEDIUM_YEARLY")}/>     
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Large</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">2.359€ / Jahr</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 50 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 25 Standorte</li>
                                            <li className="list-group-item">Bis zu 10 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 3,94€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("LARGE_YEARLY")}/>    
                                    </div>
                                </div>
                            </div>
    
                            <div className="card" style={{width: '20rem'}}>
                                <div className="card-body">
                                    <h5 className="card-title text-center">TicTrack - Extra</h5>
                                    <hr/>
                                    <h3 className="card-price text-center">3.229€ / Jahr</h3>
                                    <hr/>
                                    <h5>Vorteile:</h5>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">Bis zu 75 Mitarbeitenden</li>
                                            <li className="list-group-item">Bis zu 50 Standorte</li>
                                            <li className="list-group-item">Bis zu 20 Administratoren</li>
                                            <li className="list-group-item">Alle Funktionalitäten</li>
                                            <li className="list-group-item font-weight-bold">Umgerechnet 3,59€/Mitarbeiter*</li>
                                        </ul>                             
                                    <div className="redirect-button" style={{margin: '5%'}}>
                                        <PrimaryButton text="Jetzt buchen" onClick={()=> redirectToStripeCheckoutSession("EXTRA_YEARLY")}/>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="subtext">Alle Preise sind Nettopreise und verstehen sich zzgl. der gesetzlichen MwSt.</p>
                        <p className="subtext">*Bei Ausnutzung aller möglichen Mitarbeiter</p>
                    </>
                )
            };
        }
    }

    function subscriptionplan(){
        if(props.hasActiveSubsciption === false){
            return <h3 className="text-muted">Kein aktives Abonnement</h3>;
        } else{
            return <h3 className="green-text">{props.currentPlan}</h3>;
        }
    }

    return (        //TODO label leftside of switch
        <>
            <div className="abonnement-setting-header">
                <h3>Ihr Abonnement</h3>
                {subscriptionplan()}
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Sie können Ihr Abo-Modell jederzeit ändern, wenn Sie es möchten. Wenn Sie ein Downgrade machen, verlieren Sie die Vorteile zum nächsten Zahlungsdatum.</h5>
            <ChangeSettingButton buttonText="Abonnement verwalten" onClick={redirectToStripeCustomerportal}/>
            {subscriptionButtons()}
            
        </>
    )
}

export default Abonnement;