import "./ChangeSettingButton.css";
import Button from "react-bootstrap/Button";

function ChangeSettingButton(props) {
    return (
        <div className="change-setting-button-container">
            <Button variant="secondary" onClick={props.onClick}>{props.buttonText} </Button>
        </div>
    )
}

export default ChangeSettingButton;