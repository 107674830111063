import "./ValidationStep.css";
import ReactInputVerificationCode from 'react-input-verification-code';
import { useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";

function ValidationStep(props) {

    const [isLoading, setIsLoading] = useState(false);

    const [code, setCode] = useState(null);
    const [validateError, setValidateError] = useState(false);

    const [emailSendingCounter, setEmailSendingCounter] = useState(0);

    const handleCodeChange = (event) => {
        setCode(event);
    };

    const sendCode = (event) => {
        setIsLoading(true);
        axios.post('signup/validateCode?code=' + event).then(() => {
            sessionStorage.setItem("Code", event);
            props.handleNext();
        }).catch(() => {
            setIsLoading(false);
            setValidateError(true);
        })
    }

    const sendMailAgain = () => {
        setIsLoading(true);
        axios.post('signup/initialRequest', {
            "email" : sessionStorage.getItem("email"),
            "display_name" : sessionStorage.getItem("display_name")
        }).then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
        setEmailSendingCounter(emailSendingCounter + 1);
    }

    function isEmailSendingOkay() {
        if (emailSendingCounter < 3) {
            return (
                <>
                    <h6>Wenn keine E-Mail angekommen ist, können Sie hier die E-Mail nochmal versenden.</h6>
                    <a href="#" onClick={sendMailAgain}>E-Mail erneut senden</a>
                </>
            )
        }
    }

    return isLoading ? 
        <LoadingSpinner />
    :
    (
        <>
            <div className="registration-validation-step-header-container">
                <h4>Es wurde eine Bestätigung an Ihre E-Mail Adresse versendet.</h4>
                <h3>Bestätigungscode:</h3>
            </div>
            <div className="registration-validation-step-container">
                <form>
                    <ReactInputVerificationCode
                        autoFocus
                        placeholder=""
                        onChange={handleCodeChange}
                        onCompleted={sendCode}
                        length={6}
                    />
                </form>
                {validateError && <h5>Der Code ist nicht gültig.</h5>}
                {isEmailSendingOkay()}
            </div>
        </>
    )
}

export default ValidationStep;