import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
    return (
        <footer className="footer-container">
            <div className="footer-grid-container">
                <div className="column-1">
                    <h5>Social Media</h5>
                    <hr/>
                        <div className="footer-social-media-container">
                            <a href="https://www.instagram.com/tictrack.de/" target="_blank" rel="noopener noreferrer">
                                <img className="footer-social-media-icon" src={process.env.PUBLIC_URL + '/icons/instagram.png'} alt="Instagram Icon" />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100093073147358" target="_blank" rel="noopener noreferrer">
                                <img className="footer-social-media-icon" src={process.env.PUBLIC_URL + '/icons/facebook.png'} alt="Facebook Icon"/>
                            </a>
                            <a href="https://twitter.com/tictrack_de" target="_blank" rel="noopener noreferrer">
                                <img className="footer-social-media-icon" src={process.env.PUBLIC_URL + '/icons/twitter.png'} alt="Twitter Icon"/>
                            </a>
                            <a href="https://www.linkedin.com/company/tictrack/" target="_blank" rel="noopener noreferrer">
                                <img className="footer-social-media-icon" src={process.env.PUBLIC_URL + '/icons/linkedin.png'} alt="Linkedin Icon"/>
                            </a>
                        </div>
                    <h4 className="footer-logo" style={{paddingTop:'40px'}}><img src="/TicTrack Logo 64x64.png" alt="TicTrack Logo"/>TicTrack</h4>
                </div>
                <div className="column-2">
                    <h5>Links</h5>
                    <hr/>
                    <div className="footer-extra-link-container">
                        <a href="https://tictrack.de/kontakt" target="_blank" rel="noopener noreferrer"><h7>Hilfe und Kontakt</h7></a>
                        <Link to={"/impressum/"} target="_blank" rel="noopener noreferrer"><h7>Impressum</h7></Link>
                        <Link to={"/agb/"} target="_blank" rel="noopener noreferrer"><h7>AGB</h7></Link>
                        <Link to={"/datenschutzerklaerung/"} target="_blank" rel="noopener noreferrer"><h7>Datenschutz</h7></Link>
                        <Link to={"/lizenzen/"} target="_blank" rel="noopener noreferrer"><h7>Lizenzen</h7></Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;