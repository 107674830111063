import "./ChangePassword.css";
import ChangeSettingButton from "../../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import { Modal} from "react-bootstrap";
import axios from "axios";
import {useState} from "react";
import SecondaryButton from "../../../sharedcomponents/cancelButton/CancelButton";
import PrimaryButton from "../../../sharedcomponents/primaryButton/PrimaryButton";

function ChangePassword() {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [isChangingPasswordSuccessful, setIsChangingPasswordSuccessful] = useState(false);

    return (
        <>
            <div className="change-password-setting-header">
                <h3>Neues Passwort vergeben</h3>
                <h3 className="gray-text">**************</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Ihr Passwort sollten Sie immer geheim halten. Wenn Sie es ändern möchten,
                können Sie es hier tun.</h5>
            {isChangingPasswordSuccessful && <div className="successful-changing-password-message">
                <p>Es wurde erfolgreich eine E-Mail an {sessionStorage.getItem("Mail").toString()} gesendet!</p>
            </div>}
            <ChangeSettingButton buttonText="Passwort ändern" onClick={handleShow}/>
            {createModal()}
        </>
    )

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Passwort ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form-element">
                        <p>Möchten Sie wirklich Ihr Passwort ändern?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SecondaryButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Ja" onClick={sendPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function sendPostRequest() {
        axios.put('account/changePassword/initialRequest', {
            "email_address" : sessionStorage.getItem("Mail")
        }).then(() => {
            handleClose();
            setIsChangingPasswordSuccessful(true);
        }).catch(() => {

        })
    }
}

export default ChangePassword;