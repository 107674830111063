import "./SettingsPage.css";
import SettingsNavbar from "./navbar/SettingsNavbar";
import { Outlet } from "react-router";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";

function SettingsPage() {

    return (
        <div className="content">
            <div className="navbar-container">
                <SettingsNavbar />
            </div>
            <Outlet/>
            <Footer />
            <CookieBanner/>
        </div>
    )
}

export default SettingsPage;