import "./Account.css";
import ChangeName from "./changename/ChangeName";
import ChangeMail from "./changemail/ChangeMail";
import ChangePassword from "./changepassword/ChangePassword";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../sharedcomponents/LoadingSpinner/LoadingSpinner";
import Logout from '../../sharedcomponents/Logout'
import AskForAccess from "../../sharedcomponents/AskForAccess";
import ChangeSettingButton from "../../sharedcomponents/changesettingbutton/ChangeSettingButton";
import { useNavigate } from "react-router";

function Account() {

    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getAccountData();
    }, [])

    function getAccountData() {
        axios.get('account/getInfo').then(results => {
            setDisplayName(results.data.display_name);
            setEmail(results.data.email);
            sessionStorage.setItem("Mail", results.data.email);
            AskForAccess();
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    return isLoading ? 
        <LoadingSpinner/>
        :
        <div className="account-settings-container">
            <ChangeName displayName={displayName} />
            <ChangeMail email={email}/>
            <ChangePassword />

            <div className="advanced-settings-button-container">
                <h3>Erweiterte Einstellungen</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="setting-info-text">Hier finden Sie erweiterte Einstellungen für Ihren Account.</h5>
            <ChangeSettingButton buttonText="Erweiterte Einstellungen" onClick={() => navigate("/settings/account/advanced")}/>
        </div>
}

export default Account;