import "./CreateInitialAdminAccount.css";
import { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import ClearBanner from "../../sharedcomponents/clearBanner/ClearBanner";
import Logout from "../../sharedcomponents/Logout";
import { Button, FormControl, TextField } from "@mui/material";
import Passwordcheck from "../../sharedcomponents/Passwordcheck/Passwordcheck";
import axios from "axios";

function CreateInitialAdminAccount() {

    const [usernameBlank, setUsernameBlank] = useState(false);
    const [nameBlank, setNameBlank] = useState(false);

    const [password, setPassword] = useState("");
    const [checkPasswordForm, setCheckPasswordForm] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        if (sessionStorage.getItem("access_to_create_initial_admin_page") === "Succesful") {
        } else {
            Logout();
        }
    }, [])

    const[initialRender, setInitialrender] = useState(true);

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)
        } else {
            sendPostRequest();
        }
    }, [password]);

    const sendPostRequest = () => {
        axios.post('administrator/create', {
            "username": document.getElementById("InitialAdministratorUsername").value,
            "name": document.getElementById("InitialAdministratorName").value,
            "password": password
        }).then(() => {
            window.location.assign("/settings/home/");
        }).catch(() => {
            if(document.getElementById("InitialAdministratorUsername").value === "") setUsernameBlank(true)
            if(document.getElementById("InitialAdministratorName").value === "") setNameBlank(true)
        })     
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setCheckPasswordForm(true);
        if(document.getElementById("InitialAdministratorUsername").value === "") setUsernameBlank(true)
        if(document.getElementById("InitialAdministratorName").value === "") setNameBlank(true)
    }

    function createAdminForm() {
        return (
            <div className="initial-admin-creation-form-container">
                <form onSubmit={handleSubmit}>      
                    <FormControl sx={{width: '90%'}} className="initial-admin-creation-form-control">
                        <TextField                                 
                            autoFocus 
                            error={usernameBlank} 
                            required 
                            margin="normal" 
                            id="InitialAdministratorUsername" 
                            label="Benutzername" 
                            type="text"                             
                            variant="standard"
                            onChange={e => setUsernameBlank(e.target.value === "")}
                            helperText={usernameBlank ? "Benutzername ist leer.": false}
                        />
                    </FormControl>

                    <FormControl 
                        sx={{
                            width: '90%',
                            '@media screen and (max-width: 1000px)': {
                                width: '100%'
                            }
                        }} >
                        <TextField 
                            error={nameBlank} 
                            required 
                            margin="normal" 
                            id="InitialAdministratorName" 
                            label="Name" 
                            type="text"                             
                            variant="standard" 
                            onChange={e => setNameBlank(e.target.value === "")}
                            helperText={nameBlank ? "Name ist leer.": false}
                        />
                    </FormControl>
                    <Button type="submit" hidden/>
                </form>
                <Passwordcheck 
                    buttonText= "Administrator erstellen"
                    giveNewPassword={newPassword => setPassword(newPassword)} 
                    extraFormValidation={handleSubmit} 
                    activateFormValidation={checkPasswordForm} 
                    setActivateFormValidation={setCheckPasswordForm}
                />  
            </div>  
        )
    }

    return (
        <>
            <ClearBanner />
            <div className="create-initial-admin-account-header">
                <h3>Administrator Account erstellen</h3>
            </div>
            <hr className="divider-solid"/>
            <h5 className="create-initial-admin-account-info-text">Um nun die Zeiterfassung für Ihr Unternehmen zu nutzen, haben Sie hier die Möglichkeit einen Administrator Account zu erstellen. Dieser Account hat die Möglichkeit aktuelle Mitarbeiter- und Standorddaten anzusehen und zu bearbeiten. Zusätzlich kann dieser Account die Arbeitszeiten exportieren.</h5>
            <h5 className="create-initial-admin-account-info-text">Dieser Administrator (Sie oder ein von Ihnen ausgewählter Mitarbeiter) kann sich mit diesem Account in der TicTrack Adminoberfläche anmelden.</h5>
            {createAdminForm()}
            <Footer />
        </>
    )
}

export default CreateInitialAdminAccount;