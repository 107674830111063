import "./Licenses.css";
import Footer from "../footer/Footer";
import CookieBanner from "../sharedcomponents/CookieBanner";
import BackButton from "../sharedcomponents/backButton/BackButton";
import { Link } from "react-router-dom";

function Licenses() {
    return (
        <>
            <div className="licenses-container">
                <Link to={"/settings/home/"}><BackButton text="Zurück" /></Link>
                <h1>Lizenzen</h1>
                <p>Für Informationen der verwendeten Lizenzen, gerne ans uns wenden (info@tictrack.de).</p>
            </div>
            <Footer />
            <CookieBanner />
        </>
    )
}

export default Licenses;