import "./PasswordStep.css";
import { useState, useEffect } from "react";
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner.js";
import axios from "axios";
import Passwordcheck from "../../../sharedcomponents/Passwordcheck/Passwordcheck";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Link } from "react-router-dom";
import AskForAccess from "../../../sharedcomponents/AskForAccess";

function PasswordStep() {
    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState(null);

    const [agbIsChecked, setAgbIsChecked] = useState(false);
    const [avvIsChecked, setAvvIsChecked] = useState(false);
    const [policyIsChecked, setPolicyIsChecked] = useState(false);

    const handleAgbChange = (event) => {
        setAgbIsChecked(event.target.checked);
    };

    const handleAvvChange = (event) => {
        setAvvIsChecked(event.target.checked);
    };

    const handlePolicyChange = (event) => {
        setPolicyIsChecked(event.target.checked);
    };

    const sendData = () => {
        setIsLoading(true);
        if (agbIsChecked && avvIsChecked && policyIsChecked) {
            axios.post('signup/finalize', {
                "code" : sessionStorage.getItem("Code"),
                "password" : password,
                "has_accepted_agb": agbIsChecked,
                "has_accepted_avv": avvIsChecked,
            }).then((result) => {
                localStorage.setItem("creating_new_account", "Succesful");
                localStorage.setItem("jwtToken", result.data.access_token);
                AskForAccess();
            }).catch(() => {
                setIsLoading(false);
                setPassword("");
            })
        } else {
            setPassword("");
            setIsLoading(false);
        }
    }

    const[initialRender, setInitialrender] = useState(true) //disable call of this useEffect on first render

    useEffect(() => {
        if(initialRender){
            setInitialrender(false)
        } else {
            sendData();
        }
    }, [password]);

    return isLoading ? 
        <LoadingSpinner />
    :
    (
        <>
            <div className="registration-password-step-container">
                <FormGroup>
                    <FormControlLabel control={<Checkbox id="AcceptAGBCheckbox" onChange={handleAgbChange} required />} label={
                        <>
                            {"Ich akzeptiere die "}
                            <Link to={"/agb/"} target="_blank" rel="noopener">
                                Allgemeinen Geschäftsbedingungen (AGB)
                            </Link>
                            {" der TicTrack UG (haftungsbeschränkt)"}
                        </>
                    }  />
                    <FormControlLabel control={<Checkbox id="AcceptAVVCheckbox" onChange={handleAvvChange} required />} label={
                        <>
                            {"Ich akzeptiere den "}
                            <Link to={process.env.PUBLIC_URL + '/TicTrack_AVV.pdf'} target="_blank" rel="noopener">
                                Auftragsverarbeitungsvertrag (AVV)
                            </Link>
                            {" der TicTrack UG (haftungsbeschränkt)"}
                        </>
                    }  />
                    <FormControlLabel control={<Checkbox id="AcceptPolicyCheckbox" onChange={handlePolicyChange} required />} label={
                        <>
                            {"Ich habe die "}
                            <Link to={"/datenschutzerklaerung/"} target="_blank" rel="noopener">
                                Datenschutzerklärung
                            </Link>
                            {" gelesen und zur Kenntnis genommen."}
                        </>
                    }  />
                </FormGroup>
                <Passwordcheck
                    giveNewPassword={newPassword => setPassword(newPassword)}    
                    buttonText = "Account erstellen"  
                /> 
            </div>
        </>
    )
}

export default PasswordStep;