import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from "react-router";
import RegisterPage from "./components/registerpage/RegisterPage";
import LoginPage from "./components/loginpage/LoginPage";
import SettingsPage from "./components/settingspage/SettingsPage";
import Account from "./components/settingspage/account/Account";
import Home from "./components/settingspage/home/Home";
import Payment from "./components/settingspage/payment/Payment";
import AdminAccounts from "./components/settingspage/adminaccounts/AdminAccounts";
import TimeRecordingConfiguration from "./components/settingspage/timerecordingconfiguration/TimeRecordingConfiguration";
import AdminDetailView from "./components/settingspage/adminaccounts/detailview/AdminDetailView";
import ResetMail from "./components/resetEmailPage/initial/ResetMail";
import ValidateNewMail from "./components/resetEmailPage/validate/ValidateNewMail";
import ResetPassword from "./components/resetPasswordPage/ResetPassword";
import Impressum from './components/impressum/Impressum';
import Errorpage from './components/errorpage/Errorpage';
import Licenses from './components/licenses/Licenses';
import Policy from './components/policy/Policy';
import Agb from './components/agb/Agb';
import ChooseAbonnement from './components/afterRegistration/chooseAbonnement/ChooseAbonnement';
import CreateInitialAdminAccount from './components/afterRegistration/createInitialAdminAccount/CreateInitialAdminAccount';
import AdvancedSettings from './components/settingspage/account/advancedSettings/AdvancedSettings';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<LoginPage/>}/>
                    <Route path="register" element={<RegisterPage/>}/>
                    <Route path="login" element={<LoginPage/>}/>
                    <Route path="resetEmail" element={<ResetMail/>}/>
                    <Route path="validateNewMail" element={<ValidateNewMail/>}/>
                    <Route path="resetPassword" element={<ResetPassword/>}/>
                    <Route path="validateNewPassword" element={<ValidateNewMail/>}/>
                    <Route path='settings' element={<SettingsPage/>}>
                        <Route path="home" element={<Home/>}/>
                        <Route path='account' element={<Account/>} />
                        <Route path='account/advanced' element={<AdvancedSettings/>} />
                        <Route path="billing" element={<Payment/>}/>
                        <Route path="adminaccounts" element={<AdminAccounts/>}/>
                        <Route path="adminaccounts/adminView" element={<AdminDetailView />}/>
                        <Route path="timetracking" element={<TimeRecordingConfiguration/>}/>
                    </Route>
                    <Route path="impressum" element={<Impressum />}/>
                    <Route path="agb" element={<Agb />}/>
                    <Route path="lizenzen" element={<Licenses />}/>
                    <Route path="datenschutzerklaerung" element={<Policy />}/>
                    <Route path="chooseAbonnement" element={<ChooseAbonnement />}/>
                    <Route path="createInitialAdminAccount" element={<CreateInitialAdminAccount />}/>
                </Route>
                <Route path='*' element={<Errorpage/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
