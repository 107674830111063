import * as React from 'react';
import { Box } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import "./RegisterPage.css";
import DataStep from './steps/dataStep/DataStep';
import ValidationStep from './steps/validationStep/ValidationStep';
import PasswordStep from './steps/passwordStep/PasswordStep';
import Footer from '../footer/Footer';
import CookieBanner from '../sharedcomponents/CookieBanner';

function RegisterPage() {

    const steps = ['Daten angeben', 'Bestätigung', 'Passwort festlegen'];
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
                stepProps.completed = false;
            }
            return (
                <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
        })}
        </Stepper>
        {activeStep === steps.length ? (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    Sie haben die Einrichtung abgeschlossen.
                </Typography>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    {activeStep === 0 && <DataStep handleNext={handleNext} />}
                    {activeStep === 1 && <ValidationStep handleNext={handleNext} />}
                    {activeStep === 2 && <PasswordStep />}
                </Typography>
            </React.Fragment>
        )}
        </Box>
        <Footer />
        <CookieBanner/>
        </>
    );
}

export default RegisterPage;