import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import LoadingSpinner from "../../../sharedcomponents/LoadingSpinner/LoadingSpinner";

import "./DataStep.css";
import React, { useState } from 'react';
import { Typography } from '@mui/material';

function DataStep(props) {

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState(null);
    const [validName, setValidName] = useState(true);

    const [mail, setMail] = useState(null);
    const [vaildMail, setValidMail] = useState(true);

    const [errorMessage, setErrorMessage] = useState(false);

    const handleNameCorrection = (event) => {
        const enteredName = event.target.value;
        setName(enteredName);
    
        if (enteredName !== "")
            setValidName(true);
    }

    const handleNameChange = (event) => {
        const enteredName = event.target.value;
        setName(enteredName);
    
        setValidName(!!enteredName);
    };

    const handleEmailChange = (event) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setValidMail(emailRegex.test(event.target.value));
    };

    const handleEmailCorrection = (event) => {
        const enteredEmail = event.target.value;
        setMail(enteredEmail);
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(enteredEmail))
            setValidMail(true);
    }

    const sendData = (event) => {
        setIsLoading(true);
        event.preventDefault();
        axios.post('signup/initialRequest', {
            "email" : mail,
            "display_name" : name
        }).then(() => {
            sessionStorage.setItem("email", mail);
            sessionStorage.setItem("display_name", name);
            props.handleNext();
        }).catch(() => {
            setErrorMessage(true);
            setIsLoading(false);
        })
    }

    return isLoading ? 
        <LoadingSpinner />
    :
    (
        <form className="registration-data-step-container" onSubmit={sendData} >
            <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
                >
                <TextField error={!validName} required id="outlined-basic" onChange={handleNameCorrection} onBlur={handleNameChange} label="Name der Firma" variant="outlined" />
            </Box>
            <Box
                sx={{
                    '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
                >
                <TextField type="email" onChange={handleEmailCorrection} onBlur={handleEmailChange} error={!vaildMail} required id="outlined-basic" label="E-Mail Adresse" variant="outlined" />
            </Box>
            {errorMessage && <Typography color="red">Ein Fehler ist aufgetreten. Bitte prüfen Sie Ihre Angaben.</Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" type='submit' style={{ backgroundColor: 'var(--tictrack-primary-color)' }}>
                    Weiter
                </Button>
            </Box>
        </form>
    )
}

export default DataStep;